import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { EAPITagType } from "./enums";
import { getClientTimestamp } from "./utils";

export const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const JWT_TOKEN_KEY = "jwtToken";

export const baseApi = createApi({
  reducerPath: "baseApi",
  baseQuery: fetchBaseQuery({
    baseUrl: REACT_APP_API_ENDPOINT || "http://localhost:1337",
    credentials: "include",
    prepareHeaders(headers, { endpoint }) {
      // Do not set the authorization header for the auth endpoint
      if (endpoint === "auth" || endpoint === "register") {
        return headers;
      }
      // Get the JWT token from local storage
      const token = localStorage.getItem(JWT_TOKEN_KEY);

      // Set the authorization header with the bearer token
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      return headers;
    },
  }),
  tagTypes: [
    EAPITagType.USERS,
    EAPITagType.MATES_LIST,
    EAPITagType.MATES_GET_REWARDS,
    EAPITagType.LEADERBOARD,
    EAPITagType.VALIDATE_USER_IN_GROUP,
    EAPITagType.TASKS,
    EAPITagType.STATS,
    EAPITagType.STAKE_CREATE,
    EAPITagType.CLAIM_STAKING,
    EAPITagType.USER_PASSIVE_INCOME,
    EAPITagType.USER_PASSIVE_INCOME_CLAIM,
    EAPITagType.USER_PASSIVE_INCOME_INCREASE_LEVEL,
    EAPITagType.WAI_LEADERBOARD,
    EAPITagType.NOTIFY_USERS,
  ],
  endpoints: () => ({}),
});

const AUTH_TOKEN = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJjdXN0b21lcklkIjoiV0NvaW4iLCJwZXJtaXNzaW9ucyI6IltWZXJpZnlUYXNrXSIsImlhdCI6MTcyOTk3MjgzOSwiZXhwIjoxNzMyNTY0ODM5fQ.AL6GLlHtK2ZfpydlVVJqsgy1WnIZe-GYfd43kld9A4sKHlGcrVkgNSzVMJrjf4GsvQoP25brE3DHClS2y5u7xBAvEHZ0tBZxxGcOEf-0rwfUqJLhqgV2nd-wUznBy4W2pqiWEzpRWST4m9yCf3yMl1tWHj38PvVW92Dc5yz1nVVmtp2-kYwgQGxa1kQ_Q7rFo41-7Uy8IkHyOBRXyhtLRMe3ocdKTgIU2BKIixjRomipjuZwx1dtr7sdY3EFzUNXTHzwwRM3eTQGxlfCOl5zdVUzZy4HewRmg227H3mCaJI5aaFLLB09qpaYne8nEhDbQwRHrJ1G9harBvwaC34Cbw';

export const legendBaseApi = createApi({
  reducerPath: "legendApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://partnerapi.legendscommunity.app/",
    credentials: "include",
    prepareHeaders(headers) {
      headers.set("Authorization", `Bearer ${AUTH_TOKEN}`);
      headers.set('Content-Type', 'application/json');

      return headers;
    },
  }),
  tagTypes: [
    EAPITagType.LEGENDS
  ],
  endpoints: () => ({}),
});

export const externalBaseApi = createApi({
  reducerPath: "externalApi",
  baseQuery: fetchBaseQuery({
    credentials: "include",
    baseUrl: REACT_APP_API_ENDPOINT || "http://localhost:1337",
    prepareHeaders(headers) {
      // @ts-ignore
      const initData = window?.Telegram?.WebApp?.initData || process.env.REACT_APP_INIT_DATA_EXTERNAL_QUERY;
      const encodedInitData = btoa(initData);

      const clientTimestamp = getClientTimestamp().toString();
  
      headers.set("x-init-data", encodedInitData);
      headers.set("x-request-timestamp", clientTimestamp);
      headers.set("Content-Type", "application/json");

      return headers;
    },
  }),
  tagTypes: [
    EAPITagType.EXTERNAL_API
  ],
  endpoints: () => ({}),
});

export const okxCheckUidBaseApi = createApi({
  reducerPath: 'okxCheckUid',
  baseQuery: fetchBaseQuery({
    credentials: 'include',
    baseUrl: 'https://www.okx.com',
    prepareHeaders: (headers) => {
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  tagTypes: [EAPITagType.OKX_CHECK_UID],
  endpoints: () => ({}),
});




