import React from "react";
import { Oval } from "react-loader-spinner";

interface IProps {
  isLoading: boolean;
}

export const Loading: React.FC<IProps> = ({ isLoading }) => {
  return (
    isLoading ? (
      <div className="app-spinner" style={{ top: 0, left: 0 }}>
        <Oval
          visible={isLoading}
          height="100"
          width="100"
          color="#9747FF"
          secondaryColor="rgb(225, 219, 239)"
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    ) : null
  );
};
