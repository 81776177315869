import React from "react";

import { EPayPassTab } from "../models";

import "./styles.css";

interface IProps {
  payPassTab: EPayPassTab;
  onPayPassTabChange: (type: EPayPassTab) => void;
  payPassPurchasedType: EPayPassTab | null;
}

const type: Record<EPayPassTab, string> = {
  [EPayPassTab.COMPLETE]: "Complete",
  [EPayPassTab.LIGHT]: "Lite",
};

export const Tabs: React.FC<IProps> = ({
  payPassTab,
  onPayPassTabChange,
  payPassPurchasedType,
}) => {
  return payPassPurchasedType ? (
    <div
      onClick={() => onPayPassTabChange(EPayPassTab.COMPLETE)}
      className={`pay-pass-tabs-left-tab pay-pass-tabs-active-tab pay-pass-purchased`}
    >
      <p>{type[payPassPurchasedType]}</p>
    </div>
  ) : (
    <div className="pay-pass-tabs-root">
      <div
        onClick={() => onPayPassTabChange(EPayPassTab.COMPLETE)}
        className={`pay-pass-tabs-left-tab ${
          payPassTab === EPayPassTab.COMPLETE ? "pay-pass-tabs-active-tab" : ""
        }`}
      >
        <p>Complete</p>
      </div>
      <div
        onClick={() => onPayPassTabChange(EPayPassTab.LIGHT)}
        className={`pay-pass-tabs-right-tab ${
          payPassTab === EPayPassTab.LIGHT ? "pay-pass-tabs-active-tab" : ""
        }`}
      >
        <p>Lite</p>
      </div>
    </div>
  );
};
