export enum ECampaign {
  OKX_CAMPAIGN = "OKX_CAMPAIGN",
  OKX_IN_X = "OKX_IN_X",
  JOIN_NEW_TG_CHANNEL = "JOIN_NEW_TG_CHANNEL",
}

export interface ICampaign {
    icon: string;
    link: string;
    type: ECampaign;
    title: string;
    reward: number;
    connection?: boolean;
    externalLink?: boolean;
    internalLink?: boolean;
    is_done?: boolean;
}

export interface ClaimTaskProps {
  task: ICampaign;
}