import React from "react";

import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { useLocation, useNavigate } from "react-router-dom";

import "./index.scss";

import { UserContext } from "../contexts/user-context";
import { useClaimStakingMutation } from "../feature/users";
import { TClaimState } from "../contexts/types";

export const useDashboardApi = () => {
  const [impactOccurred] = useHapticFeedback();

  const location = useLocation();
  const navigate = useNavigate();

  const [claimStaking, claimStakingState] = useClaimStakingMutation();

  const { user, isLoading, setClaimState, setIsTimestampError } =
    React.useContext(UserContext);

  const [stackToGet, setStackToGet] = React.useState<{
    amount: number;
    referralRewards: number;
    timestamp: number;
    days: number;
  } | null>(null);

  const handleNew = () => {
    impactOccurred("heavy");
    navigate("/staking");
  };

  React.useEffect(() => {
    if (!location.state?.stayHerePage) {
      navigate("/staking");
    }
  }, [location]);

  function getTimeLeft(dateWhenStaked: number, stakeDays: number) {
    const now = Date.now();

    const adjustedTimestamp =
      dateWhenStaked * 1000 + stakeDays * 24 * 60 * 60 * 1000;
    const diff = adjustedTimestamp - now;

    if (diff <= 0) {
      return "0d 0h 0m";
    }

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    return `${days}d ${hours}h ${minutes}m`;
  }

  function canGetStaking(dateWhenStaked: number, stakeDays: number) {
    const now = Date.now();

    const adjustedTimestamp =
      dateWhenStaked * 1000 + stakeDays * 24 * 60 * 60 * 1000;
    const diff = adjustedTimestamp - now;

    return diff <= 0;
  }
  const handleGetStacking = async () => {
    if (stackToGet) {
      if (!canGetStaking(stackToGet.timestamp, stackToGet.days)) {
        setIsTimestampError?.(true);
      } else {
        impactOccurred("heavy");

        if (stackToGet.timestamp) {
          claimStaking({ timestamp: stackToGet.timestamp })
            .then((res) => {
              if (
                // @ts-ignore
                res?.data?.data?.balance &&
                // @ts-ignore
                res?.data?.data?.balance_from_clicks &&
                // @ts-ignore
                res?.data?.data?.stakes
              ) {
                const newData = {
                  // @ts-ignore
                  balance: res.data.data.balance,
                  // @ts-ignore
                  balance_from_clicks: res.data.data.balance_from_clicks,
                  // @ts-ignore
                  stakes: res.data.data.stakes,
                } as TClaimState;

                setClaimState?.((prevState) => ({ ...prevState, ...newData }));
                setStackToGet(null);

                if (
                  // @ts-ignore
                  res.data.data.stakes.filter((i) => i?.completed !== true)
                    ?.length === 0
                ) {
                  navigate("/staking");
                }
              }
            })
            .catch((e: any) => {
              console.warn(e);
            });
        }
      }
    }
  };

  return {
    getTimeLeft,
    handleNew,
    isLoading,
    user: user.claim_state,
    canGetStaking,
    isFetching: claimStakingState.isLoading,
    onGetStacking: handleGetStacking,
    stackToGet,
    onChangeStackToGet: setStackToGet,
  };
};
