import React from "react";

import { EWaiUpgradeType } from "../../models";
import { IUserPassiveIncomeData } from "../../../feature/users/models";

import "./index.scss";

// images
const experts = require("../../wai-content/balance/img/experts.png") as string;
const successIcon = require("./img/success-notification-icon.png") as string;
const errorIcon = require("./img/error-notification-icon.png") as string;

interface IProps {
  data: IUserPassiveIncomeData;
  selectedUpgradeType: EWaiUpgradeType;
}

export const UpgradeTypeStatistics: React.FC<IProps> = ({
  data,
  selectedUpgradeType,
}) => {
  const list = React.useMemo(() => {
    const obj = {
      [EWaiUpgradeType.MARKET_ANALYSIS]: {
        label: "Market Analysis",
        canUpdate: data.features.market_analysis.current_level + 5 === data.features[selectedUpgradeType].current_level,

        level: data.features.market_analysis.current_level,
      },
      [EWaiUpgradeType.TRADING_VOLUME]: {
        label: "Trading Volume",
        canUpdate:  data.features.trading_volume.current_level + 5 === data.features[selectedUpgradeType].current_level,
        level: data.features.trading_volume.current_level,
      },
      [EWaiUpgradeType.PREDICTION_ACCURACY]: {
        label: "Prediction Accuracy",
        canUpdate:  data.features.prediction_accuracy.current_level + 5 === data.features[selectedUpgradeType].current_level,
        level: data.features.prediction_accuracy.current_level,
      },
      [EWaiUpgradeType.TRADING_EXPERTS]: {
        label: "Experts Team",
        canUpdate:  data.features.trading_experts.current_level + 5 === data.features[selectedUpgradeType].current_level,
        level:data.features.trading_experts.current_level,
      },
    };

    delete obj[selectedUpgradeType];

    return Object.values(obj);
  }, [data.features, selectedUpgradeType]);

  const isNextLevelPriceCoin = data.features[selectedUpgradeType].next_level_price.currency === 'passive_coin';

  const getPrice = () => {
    if (isNextLevelPriceCoin) {
        return `${data.balance}/${data.features[selectedUpgradeType].next_level_price.amount} WAI`
    } else {
        return  <>{`${data.mates_balance}/${data.features[selectedUpgradeType].next_level_price.amount}`} <img width="20px" src={experts} alt="" /></>
    }
  }

  return (
    <div className="upgrade-type-statistics-wrapper">
      <div className="upgrade-type-statistics-root">
        {list.map((item) => {

          return  <div className="upgrade-type-statistics-item">
          <p className="white-color">{item.label}</p>
          <div className="upgrade-type-statistics-level">
            <p className="white-color">lvl. {item.level}</p>
            <img src={item.canUpdate ?  errorIcon :  successIcon} alt="" />
          </div>
        </div>
        })}
      </div>
      <div>
        <div className="white-color upgrade-type-statistics-price ab">
            <p>Price</p>
            <p>{getPrice()}</p>
        </div>
      </div>
    </div>
  );
};
