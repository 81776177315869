export enum EAzurGames {
  SWORD_PLAY = "SWORD_PLAY",
  HITMASTER = "HITMASTER",
}
export interface IAzurGames {
    icon: string;
    modalIcon: string;
    gameLink: string;
    type: EAzurGames;
    title: string;
    modalTitle: string;
    reward: number;
    timestamp?: number;
}