import React from "react";

import "./index.scss";
import { FreeBoosters } from "./free-boosters";
import { MainBoosters } from "./main-boosters";
import { EBooster } from "./types";
import { ERune, TRune } from "../../hooks/rune/models";

interface IProps {
  onOpen: (booster: EBooster) => void;
  multiplierLevel: number;
  chargerLevel: number;
  maximizerLevel: number;
  isMultiplierUsed: boolean;
  isWorkerBoostsUsed: boolean;
  availableEnergyBoosts: number;
  availableDiceBoosts: number;
  runeType: ERune;
  onSetRuneStep?: (runeStep: TRune) => void;
  counter: {
    start: number;
    end: number;
  };
  setCounter: React.Dispatch<
    React.SetStateAction<{
      start: number;
      end: number;
    }>
  >;
  payPassMultiplier?: number;
}

export const Boosters: React.FC<IProps> = ({
  onOpen,
  multiplierLevel,
  chargerLevel,
  maximizerLevel,
  availableEnergyBoosts,
  availableDiceBoosts,
  isMultiplierUsed,
  isWorkerBoostsUsed,
  counter,
  setCounter,
  onSetRuneStep,
  runeType,
  payPassMultiplier,
}) => {
  return (
    <div className="boosters-root">
      <FreeBoosters
        onOpen={onOpen}
        availableEnergyBoosts={availableEnergyBoosts}
        availableDiceBoosts={availableDiceBoosts}
        payPassMultiplier={payPassMultiplier}
      />
      <MainBoosters
        onOpen={onOpen}
        multiplierLevel={multiplierLevel}
        chargerLevel={chargerLevel}
        maximizerLevel={maximizerLevel}
        isMultiplierUsed={isMultiplierUsed}
        isWorkerBoostsUsed={isWorkerBoostsUsed}
        counter={counter}
        setCounter={setCounter}
        onSetRuneStep={onSetRuneStep}
        runeType={runeType}
      />
    </div>
  );
};
