import React from "react";

import { SmallCoin } from "../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_MEDIUM } from "../../../consts";
import { formatValues } from "../../../utils";
import { PayPassMultiplierComponent } from "../pay-pass-multiplier";
import { ITask } from "../type";

interface IProps {
  currentTask: ITask | null;
  isClaimed: boolean;
  payPassMultiplier?: number;
  modalLabel?: string;
}

export const ExternalVerifyReceived: React.FC<IProps> = ({
  currentTask,
  isClaimed,
  payPassMultiplier,
  modalLabel,
}) => {
  return (
    <>
      <div className="task-modal-reward ab">
        <SmallCoin
          width={BALANCE_COIN_SIZE_MEDIUM}
          height={BALANCE_COIN_SIZE_MEDIUM}
        />
        <p>+{formatValues(currentTask?.reward)}</p>
        <PayPassMultiplierComponent payPassMultiplier={payPassMultiplier} />
      </div>
      {isClaimed && <p className="task-modal-received">Received</p>}
      <p className="task-modal-received">
        {modalLabel ?? "Complete task to get"}
      </p>
    </>
  );
};
