import React, { useEffect } from "react";

import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { ExplosionConfetti } from "./fire-works-components";
import { Canvas } from "@react-three/fiber";
import "./styles.scss";

interface IProps {
  isVisible: boolean;
  onChange: () => void;
}

export const FireWorksAnimation: React.FC<IProps> = ({
  isVisible,
  onChange,
}) => {
  const [start, setStart] = React.useState(false);
  const [impactOccurred] = useHapticFeedback();

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;

    if (!timer && isVisible && !start) {
      timer = setTimeout(() => {
        onChange();
        setStart(false);
      }, 7000);
    }

    if (isVisible && start) {
      impactOccurred("heavy");
      setStart(false);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [isVisible, onChange, start]);

  return (
    <>
      {isVisible && (
        <Canvas
          className={isVisible ? "visible" : "hidden"}
          style={{
            position: "absolute",
            zIndex: 100000000,
          }}
          dpr={1}
          shadows
          gl={{ antialias: false }}
        >
          <ExplosionConfetti
            rate={10}
            fallingHeight={10}
            amount={20}
            enableShadows
            fallingSpeed={4}
            isExploding
          />
        </Canvas>
      )}
    </>
  );
};
