import React from "react";

import Tilt from "react-parallax-tilt";

interface IProps extends React.PropsWithChildren {
  manualTiltAngleX: number;
  manualTiltAngleY: number;
}

export const TiltAnimation: React.FC<IProps> = ({
  manualTiltAngleX,
  manualTiltAngleY,
  children,
}) => {
  return (
    <Tilt
      tiltAngleXManual={manualTiltAngleX}
      tiltAngleYManual={manualTiltAngleY}
      tiltReverse={true}
      glareEnable={false}
      glareMaxOpacity={0.7}
      glareColor="#ffffff"
      glarePosition="bottom"
      glareBorderRadius="50%"
    >
      {children}
    </Tilt>
  );
};
