import React from "react";

import { EWaiUpgradeType } from "../../models";
import { IUserPassiveIncomeData } from "../../../feature/users/models";

import { WaiUpgradeButton } from "./wai-upgrade-button";
import "./index.scss";

// images
const loopIcon = require("./img/loop-icon.png") as string;
const tradingIcon = require("./img/trading-icon.png") as string;
const predictionIcon = require("./img/prediction-icon.png") as string;
const expertIcon = require("./img/expert-icon.png") as string;

interface IProps {
  onChange: (type: EWaiUpgradeType | null) => void;
  data: IUserPassiveIncomeData;
  selectedUpgradeType: EWaiUpgradeType | null;
  minItemSize: number;
}

export const UpgradeButtons: React.FC<IProps> = ({
  onChange,
  data,
  selectedUpgradeType,
  minItemSize,
}) => {

  return (
    <>
      <div className="wai-upgrade-body-item">
          <WaiUpgradeButton
            size={minItemSize}
            classes={{
              root: "wai-upgrade-market-analysis-icon",
              level: "wai-upgrade-market-analysis-level-size wai-upgrade-level",
              notification:
                "wai-upgrade-notification wai-upgrade-market-analysis-notification",
            }}
            icon={loopIcon}
            onChange={() => onChange(EWaiUpgradeType.MARKET_ANALYSIS)}
            data={data}
            type={EWaiUpgradeType.MARKET_ANALYSIS}
            scale={0.8}
            selectedUpgradeType={selectedUpgradeType}
          />
      </div>

      <div className="wai-upgrade-body-item">
          <WaiUpgradeButton
            size={minItemSize}
            classes={{
              root: "wai-upgrade-trading-volume-icon",
              level: "wai-upgrade-trading-volume-level-size wai-upgrade-level",
              notification:
                "wai-upgrade-notification wai-upgrade-trading-volume-notification",
            }}
            icon={tradingIcon}
            onChange={() => onChange(EWaiUpgradeType.TRADING_VOLUME)}
            data={data}
            type={EWaiUpgradeType.TRADING_VOLUME}
            scale={0.6}
            selectedUpgradeType={selectedUpgradeType}

          />
      </div>
      <div className="wai-upgrade-body-item">
        <WaiUpgradeButton
            size={minItemSize}
            classes={{
            root: "wai-upgrade-expert-icon",
            level: "wai-upgrade-expert-level-size wai-upgrade-level",
            notification:
              "wai-upgrade-notification wai-upgrade-expert-notification",
          }}
          icon={expertIcon}
          onChange={() => onChange(EWaiUpgradeType.TRADING_EXPERTS)}
          data={data}
          type={EWaiUpgradeType.TRADING_EXPERTS}
          scale={0.6}
          selectedUpgradeType={selectedUpgradeType}

        />
      </div>
      <div className="wai-upgrade-body-item">
        <WaiUpgradeButton
            size={minItemSize}
            classes={{
            root: "wai-prediction-prediction-accuracy-icon",
            level:
              "wai-prediction-prediction-accuracy-level-size wai-upgrade-level",
            notification:
              "wai-upgrade-notification wai-upgrade-prediction-accuracy-notification",
          }}
          icon={predictionIcon}
          onChange={() => onChange(EWaiUpgradeType.PREDICTION_ACCURACY)}
          data={data}
          type={EWaiUpgradeType.PREDICTION_ACCURACY}
          scale={1}
          selectedUpgradeType={selectedUpgradeType}

        />
      </div>
    </>
  );
};
