import React from "react";

import { TonConnectButton } from "@tonconnect/ui-react";
import { Address } from "@ton/core";

import { BottomAnimation } from "../../page-animation";
import { truncateString } from "../../wallet/utils";

import { STARS_COMPLETE_PRICE, STARS_LIGHT_PRICE } from "../consts";

import "./styles.css";

// images
const editIcon = require("./img/edit-icon.png") as string;
const starsIcon = require("./img/stars.png") as string;

interface IProps {
  address?: string;
  isWalletConnected: boolean;
  onSentTransaction: () => Promise<void>;
  onGetPayPassGenerateInvoice: () => Promise<void>;
  onDisconnect: () => Promise<void>;
  disabled: boolean;
  isActiveTabPayPassComplete: boolean;
}

export const WalletActions: React.FC<IProps> = ({
  isWalletConnected,
  onSentTransaction,
  onGetPayPassGenerateInvoice,
  address,
  onDisconnect,
  disabled,
  isActiveTabPayPassComplete,
}) => {
  const btnDisabled = disabled ? "tn-disabled" : "";
  const AMOUNT = (isActiveTabPayPassComplete ? process.env.REACT_APP_PAY_PASS_AMOUNT  : process.env.REACT_APP_PAY_PASS_AMOUNT_LIGHT)  || "";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }}
    >
      <BottomAnimation>
        <div className="wallet-actions-wrapper-start-wrapper">
          {address && (
            <div className="connected-address">
              <p className="white-color paPassAddress">
                Connected address:{" "}
                {truncateString(
                  Address.parse(address).toString({
                    bounceable: false,
                  }),
                  3
                )}
              </p>
              <img src={editIcon} alt="" onClick={onDisconnect} />
            </div>
          )}
          <div className="wallet-actions-wrapper-start-wrapper-actions">
            {address && (
              <div>
                <div
                  onClick={disabled ? undefined : onSentTransaction}
                  className="wai-action-text-timer-claim ab"
                  style={{ width: "155px", height: "57px", fontSize: "19px" }}
                >
                  {disabled ? "..." : `${AMOUNT} TON`}
                </div>
              </div>
            )}
            {!address && (
              <div
                className={`wai-action-text-timer-claim wai-action-text-timer-claim-start wallet-connect-btn-paypass-wrapper wallet-connect-btn-paypass-wrapper-start wallet-connect-btn-paypass-wrapper-start-connect  ${btnDisabled} ab ${
                  isActiveTabPayPassComplete ?  "" : "pay-pass-light"
                } `}
                style={{ position: "relative" }}
              >
                <TonConnectButton
                  className={`wallet-connect-btn wallet-connect-btn-paypass wallet-connect-btn-paypass-stars-stars ab`}
                />
                <span
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    color: "inherit",
                    fontSize: "19px",
                    zIndex: 0,
                  }}
                  className="wallet-connect-btn-paypass-wrapper-start"
                >
                  Buy for TON
                </span>
              </div>
            )}

            <div style={{ height: "63.9px" }}>
              <div
                onClick={disabled ? undefined : onGetPayPassGenerateInvoice}
                className={`wai-action-text-timer-claim ab wallet-connect-btn-stars ${
                  isActiveTabPayPassComplete ? "" : "pay-pass-light"
                }`}
              >
                <img src={starsIcon} alt="" /> <p>{isActiveTabPayPassComplete ? STARS_COMPLETE_PRICE : STARS_LIGHT_PRICE}</p>
              </div>
            </div>
          </div>
        </div>
      </BottomAnimation>
    </div>
  );
};
