import React from "react";

import { CSSTransition } from "react-transition-group";

import "./styles.css";
import { PAY_PAY_REWARD, PAY_PAY_REWARD_LIGHT } from "../consts";
import { formatValues } from "../../../utils";

const coinIcon = require("./img/coin-icon.png") as string;

interface IProps {
  open: boolean;
  onClose: () => void;
  isActiveTabPayPassComplete: boolean;
}

export const SuccessPayPassModal: React.FC<IProps> = ({ open, onClose, isActiveTabPayPassComplete }) => {
  return (
    <CSSTransition in={open} timeout={2500} classNames="fade" unmountOnExit>
      <div className="overlay app-modal-root blur">
        <div
          className={`app-modal-content-wrapper task-modal-wrapper`}
          style={{ position: "relative" }}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="app-modal-content task-modal-content success-pay-pass-modal">
            <div className="success-pay-pass-modal-content">
              <img width="153px" src={coinIcon} alt="" />
              <div className="success-pay-pass-modal-content-reward">
                <div className="task-modal-reward ab">
                  <p>{formatValues(isActiveTabPayPassComplete ? PAY_PAY_REWARD : PAY_PAY_REWARD_LIGHT)} W-Coin</p>
                </div>
                <p className="task-modal-received">Welcome to W-Galaxy!</p>
              </div>
            </div>
            <div
              className="main-wrapper-actions"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                width: "90px",
              }}
            >
              <div className="wallet-actions-wrapper">
                <button onClick={onClose} className="wallet-connect-btn explore-btn">
                  <b>Jump in!</b>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
