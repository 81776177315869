import React, { useContext } from "react";

import { BackButton } from "@vkruglikov/react-telegram-web-app";

import { UserContext } from "../contexts/user-context";
import { TemplatePages } from "../template-pages";
import { ROUTES } from "../routers";
import { AD_GRAM_NEW_CODE, AD_GRAM_OLD_CODE, NEW_URL_MASK } from "../../consts";

import { Collaboration } from "./collobaration";
import { ADSGRAM_FIELD } from "./consts";
import { useAdsGram, useLocalPageTasksApi } from "./hooks";
import { Modals } from "./modals";
import { Content } from "./content";

import "./index.scss";

export const PageTasks: React.FC = () => {
  const {
    user,
    task: {
      tasks,
      isLoading,
      isFetching,
      currentTask,
      onClaimTask,
      onChangeCurrentIndexTask,
      WALLET_TASK,
      THREE_FRIENDS_TASK,
      readyToClaim,
      isJoinedToTelegram,
      onTelegramGroupVerify,
      can_update_ads,
    },
    onSetRuneStep,
    setClaimState,
  } = useContext(UserContext);

  const blockId =
    window.location.hostname === NEW_URL_MASK
      ? AD_GRAM_NEW_CODE
      : AD_GRAM_OLD_CODE;

  // AdsGram
  const onAdsGramReward = React.useCallback(() => {
    onClaimTask(ADSGRAM_FIELD);
  }, [onClaimTask]);
  const showAdvert = useAdsGram({ blockId, onReward: onAdsGramReward });

  const {
    openCollaboration,
    onCloseCollaboration,
    onOpenSupport,
    isCompletedTasksListEmpty,
    activeTab,
    onActiveTaskChange,
    activeTasksList,
    completedTasksList,
    handleSetShowVerifyModal,
    showVerifyModal,
    showShareModal,
    setShowShareModal,
    payPassMultiplier,
    bgImage,
  } = useLocalPageTasksApi(user, tasks);

  // VerifyModal
  const handleVerifyModalClose = () => {
    onChangeCurrentIndexTask(null);
    handleSetShowVerifyModal(false);
  };

  const handleVerifyModalClaim = () => {
    onClaimTask(currentTask!.type);
  };

  // TaskModal
  const handleTaskModalClose = () => {
    onChangeCurrentIndexTask(null);
  };

  const handleTaskModalClaim = () => {
    if (currentTask?.index !== null) {
      onClaimTask(currentTask!.type);
    }
  };

  // ShareModal
  const isCurrentTaskShareModal = showShareModal ? currentTask : null;

  const handleShareModalClose = () => {
    setShowShareModal(null);
  };

  const handleShareModalClaim = () => {
    if (currentTask?.index !== null) {
      onClaimTask(currentTask!.type);
    }
  };

  const handleSetShowShareModal = () => {
    setShowShareModal(true);
  };

  const handleShowAdvert = () => {
    showAdvert();
  };

  return (
    <TemplatePages isLoading={isLoading} isFetching={isFetching} bg={bgImage}>
        <BackButton onClick={() => (window.location.hash = ROUTES.MAIN.PATH)} />
      <Collaboration
        isOpen={openCollaboration}
        onClose={onCloseCollaboration}
        onOpenSupport={onOpenSupport}
      />
      <div className="page page-1 page-tasks main-wrapper">
        <Modals
          showVerifyModal={showVerifyModal}
          currentTask={currentTask}
          onVerifyModalClose={handleVerifyModalClose}
          onVerifyModalClaim={handleVerifyModalClaim}
          onTelegramGroupVerify={onTelegramGroupVerify}
          onTaskModalClose={handleTaskModalClose}
          onTaskModalClaim={handleTaskModalClaim}
          isJoinedToTelegram={isJoinedToTelegram}
          isCurrentTaskShareModal={isCurrentTaskShareModal}
          onShareModalClose={handleShareModalClose}
          onShareModalClaim={handleShareModalClaim}
          payPassMultiplier={payPassMultiplier}
        />
        <Content
          activeTab={activeTab}
          user={user}
          setClaimState={setClaimState}
          can_update_ads={can_update_ads}
          WALLET_TASK={WALLET_TASK}
          THREE_FRIENDS_TASK={THREE_FRIENDS_TASK}
          activeTasksList={activeTasksList}
          onClaimTask={onClaimTask}
          onChangeCurrentIndexTask={onChangeCurrentIndexTask}
          readyToClaim={readyToClaim}
          onSetShowVerifyModal={handleSetShowVerifyModal}
          onSetShowShareModal={handleSetShowShareModal}
          onshowAdvert={handleShowAdvert}
          completedTasksList={completedTasksList}
          isCompletedTasksListEmpty={isCompletedTasksListEmpty}
          onSetRuneStep={onSetRuneStep}
          onActiveTaskChange={onActiveTaskChange}
          payPassMultiplier={payPassMultiplier}
        />
      </div>
    </TemplatePages>
  );
};
