import React from 'react';

import { CSSTransition } from "react-transition-group";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";

import { ClaimTaskProps, ICampaign } from "../models";
import { SmallCoin } from "../../../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_MEDIUM } from "../../../../../consts";
import { formatValues } from "../../../../../utils";
import { PayPassMultiplierComponent } from "../../../pay-pass-multiplier";

import "./index.scss";
const icon = require("./img/modal-icon.png") as string;

interface IProps {
  currentTask: ICampaign | null;
  onClose: () => void;
  onClaimTask: ({ task }: ClaimTaskProps, uid?: string) => void;
  isCompleted: boolean;
  payPassMultiplier: number | undefined;
}

export const CheckUidModal: React.FC<IProps> = ({
  currentTask,
  onClose,
  onClaimTask,
  isCompleted,
  payPassMultiplier,
}) => {
  const webApp = useWebApp();

  const [errorMessage, setErrorMessage] = React.useState<boolean | null>(null);
  const [inputValue, setInputValue] = React.useState<string | undefined>(
    undefined
  );

  const isBtnDisabled = !inputValue;

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;

    if (Boolean(value)) {
      setInputValue(value as string);
    } else {
      setInputValue(undefined);
    }

    if (errorMessage) {
      setErrorMessage(null);
    }
  };

  const onClaim = () => {
    const value = inputValue || '';

    if (!/^[0-9]+$/.test(value) || (value.length < 15 || value.length > 25)) {
      setErrorMessage(true)
      return false
    } else {
      if (currentTask) {
        onClaimTask?.({ task: currentTask }, value);
      }
    }
  };

  const handleReset = () => {
    onClose();
    setInputValue("");
    setErrorMessage(null);
  };

  const handleOpenOkxPage = () => {
    webApp.openLink("https://www.okx.com/campaigns/w-coin?channelid=50403875");
  };

  const scrollToBottom = () => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  };

  return (
    <>
      {!!currentTask && (
        <div
          className={`overlay app-modal-root ${!!currentTask ? "blur" : ""}`}
        ></div>
      )}
      <CSSTransition
        in={!!currentTask}
        timeout={2500}
        classNames="fade"
        unmountOnExit
      >
        <div className="overlay app-modal-root">
          <div
            className="app-modal-content-wrapper task-modal-wrapper okx-content-wrapper"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="close-modal ab" onClick={handleReset}>
              x
            </div>
            <div className="app-modal-content task-modal-content">
              <img width="153px" src={icon} alt="" />
            </div>
            {isCompleted ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                {
                  <>
                    <p className="task-modal-received">Received</p>
                    <div className="task-modal-reward ab">
                      <SmallCoin
                        width={BALANCE_COIN_SIZE_MEDIUM}
                        height={BALANCE_COIN_SIZE_MEDIUM}
                      />
                      <p>+{formatValues(currentTask?.reward)}</p><PayPassMultiplierComponent payPassMultiplier={payPassMultiplier} />
                    </div>
                  </>
                }
              </div>
            ) : (
              <div className="okx-title-container">
                {/* <p className="ab okx-title">{currentTask?.modalTitle}</p> */}
                <p className="okx-subtitle">
                  Complete the OKX campaign steps listed on the page and enter
                  your account UID
                </p>
              </div>
            )}

            <div className="okx-actions">
              {!isCompleted && (
                <div>
                  <div className="okx-input-root">
                    <div className="okx-input-wrapper">
                      <input
                        value={inputValue}
                        type="text"
                        className="okx-mobile-input"
                        placeholder="Input UID here"
                        onChange={(e) => onInputChange(e)}
                        onBlur={scrollToBottom} 
                      />
                    </div>
                    <button
                      onClick={onClaim}
                      className={`okx-redeem-button ${
                        isBtnDisabled && "btn-disabled"
                      }`}
                    >
                      <p className="ab okx-redeem-text">Check</p>
                    </button>
                  </div>
                  <p
                    className={`okx-validation-label ${
                      errorMessage && "okx-validation-label-visible"
                    }`}
                  >
                    Invalid code
                  </p>
                </div>
              )}

              <button
                className="okx-download-button"
                onClick={handleOpenOkxPage}
              >
                <p className="ab okx-download-text">Go to OKX</p>
              </button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
