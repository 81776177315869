import React from "react";

import { CSSTransition } from "react-transition-group";

import { EWaiUpgradeType } from "../models";
import { checkMaxLevelUpgrade } from "../wai-content/utils";
import { SELECTED_UPGRADE_DATA } from "../consts";
import { IUserPassiveIncomeData } from "../../feature/users/models";

import "./index.scss";
import { Upgrade } from "./upgrade";
import { ActionButton } from "./action-button";
import { Title } from "./title";
import { UpgradeTypeStatistics } from "./upgrade-type-statistics";

interface IProps {
  show: boolean;
  onClose: () => void;
  disabled: boolean;
  selectedUpgradeType: EWaiUpgradeType | null;
  data?: IUserPassiveIncomeData;
  onIncreaseLevel: (type: EWaiUpgradeType) => void;
}

export const WaiModal: React.FC<IProps> = ({
  show,
  onClose,
  disabled,
  selectedUpgradeType,
  data,
  onIncreaseLevel,
}) => {

  const closeComponent = () => {
    return (
      <div className="close-modal ab" onClick={onClose}>
        x
      </div>
    );
  };

  const buttonDisabled = React.useMemo(() => {
    if (selectedUpgradeType && data?.features) {
      return checkMaxLevelUpgrade(selectedUpgradeType, data?.features);
    }

    return false;
  }, [data?.features, selectedUpgradeType]);

  return (
    <CSSTransition in={show} timeout={500} classNames="fade" unmountOnExit>
      {data && selectedUpgradeType ? (
        <div className="overlay app-modal-root wai-modal-padding" >
          <div
            className={`app-modal-content-wrapper task-modal-wrapper`}
            onClick={(e) => e.stopPropagation()}
            style={{
              height: '100%',
              overflowY: 'auto',
              padding: '27px 15px 15px'
            }}
          >
            {closeComponent()}
            <div className="app-modal-content task-modal-content">
              <Title
                title={
                  selectedUpgradeType
                    ? SELECTED_UPGRADE_DATA[selectedUpgradeType].title
                    : ""
                }
                subtitle={
                  selectedUpgradeType
                    ? SELECTED_UPGRADE_DATA[selectedUpgradeType].subTitle
                    : ""
                }
              />
              <Upgrade
                icon={
                  selectedUpgradeType
                    ? SELECTED_UPGRADE_DATA[selectedUpgradeType].icon
                    : ""
                }
                currentLevelRate={data.features[selectedUpgradeType].current_level_rate}
                currentLevel={data.features[selectedUpgradeType].current_level}
                nextLevelRate={data.features[selectedUpgradeType].next_level_rate}
                nextLevel={data.features[selectedUpgradeType].next_level}
              />
              <div className="wai-modal-divider"></div>
              <UpgradeTypeStatistics data={data} selectedUpgradeType={selectedUpgradeType} />
              <div className="wai-footer">
                <ActionButton
                  label={
                    selectedUpgradeType
                      ? SELECTED_UPGRADE_DATA[selectedUpgradeType].btnLabel
                      : ""
                  }
                  onClaim={() => onIncreaseLevel(selectedUpgradeType)}
                  featureLevel={data.features[selectedUpgradeType]}
                  data={data}
                  disabled={buttonDisabled}
                />
              </div>
            </div>
          </div>
        </div>
      ) : <></>}
    </CSSTransition>
  );
};
