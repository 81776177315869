import React from "react";

import { Address } from "@ton/core";
import { Oval } from "react-loader-spinner";
import { TonConnectButton } from "@tonconnect/ui-react";
import { BackButton } from "@vkruglikov/react-telegram-web-app";

import { ROUTES } from "../routers";
import { ERune } from "../hooks/rune/models";
import { TaskModal } from "../app-modal/task-modal";
import { TemplatePages } from "../template-pages";

import { useWalletApi } from "./hooks";
import { truncateString } from "./utils";
import { Content } from "./content";
import "./index.scss";

// images
const cIcon = require("../../components/hooks/rune/img/c-icon.png") as string;
const bg = require("./img/wallet-bg.png") as string;
const walletIcon = require("./img/wallet-icon.png") as string;
const verifyIcon = require("./img/verify.png") as string;

export function Wallet() {
  const {
    isLoading,
    updateUserState,
    openModal,
    setOpenModal,
    runeStep,
    onSetRuneStep,
    user,
    wallet,
    tonConnectUI,
    handleDisconnect,
    isWalletLoading,
    WALLET_TASK,
    payPassMultiplier,
  } = useWalletApi();

  return (
    <TemplatePages
      bg={bg}
      isLoading={isLoading}
      isFetching={updateUserState.isLoading}
    >
      <BackButton onClick={() => (window.location.hash = ROUTES.MAIN.PATH)} />
      {!!openModal && (
        <div
          className={`overlay app-modal-root ${!!openModal ? "blur" : ""}`}
        ></div>
      )}
      <TaskModal
        currentTask={openModal ? WALLET_TASK : null}
        onClose={() => setOpenModal(false)}
        payPassMultiplier={payPassMultiplier}
      />
      <div className="page page-1 page-tasks wallet-wrapper">
        {runeStep && (
          <img
            src={cIcon}
            onClick={() =>
              onSetRuneStep?.({
                step: ERune.C,
                completed: false,
              })
            }
            alt=""
            className="c-rune-icon"
          />
        )}
        <div className="wallet-content-wrapper">
          <div className="wallet-content-header">
            <h2 className="main-heading ab" style={{ color: "#fff" }}>
              Wallet
            </h2>
            <img src={walletIcon} alt="" width="46px" />
          </div>
          <div
            className="wallet-content-wrapper-root ${showContent && 'self-bottom"
            style={{ overflowY: "auto" }}
          >
            <Content balance={user?.claim_state?.balance} />
            <div>
              <div className="actions-wrapper">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4px",
                    marginTop: "16px",
                  }}
                >
                  {wallet?.account?.address && (
                    <>
                      <p
                        className="white-color"
                        style={{ textAlign: "center" }}
                      >
                        Account verified{" "}
                        <img src={verifyIcon} alt="" width="18px" />
                      </p>
                      <p className="white-color">
                        Connected address:{" "}
                        {truncateString(
                          Address.parse(wallet.account.address).toString({
                            bounceable: false,
                          }),
                          5
                        )}
                      </p>
                    </>
                  )}
                </div>
                {tonConnectUI.connected ? (
                  <div className="main-wrapper-actions">
                    <button
                      onClick={handleDisconnect}
                      className="main-button mate-button page-wallet-btn wallet-connect-btn waller-connect-btn-root"
                    >
                      <b>Disconnect</b>
                    </button>
                  </div>
                ) : (
                  <>
                    <div className="wallet-actions-wrapper">
                      {isWalletLoading && (
                        <div className="app-spinner">
                          <Oval
                            visible={true}
                            height="30"
                            width="30"
                            color="#9747FF"
                            secondaryColor="rgb(225, 219, 239)"
                            ariaLabel="oval-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </div>
                      )}
                      <TonConnectButton className={`wallet-connect-btn`} />
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </TemplatePages>
  );
}
