import React from "react";

import { ROUTES } from "../../routers";
const galaxyIcon = require("../img/galaxy-icon.png") as string;
const waiIcon = require("../img/wai-icon.png") as string;

export const PagesLinks: React.FC = () => {

  return (
    <div className="page-claim-actions">
        <>
          <a
            href={`#${ROUTES.PAY_PASS.PATH}`}
            className="page-claim-leaderboard-link ab"
          >
            <img width="152px" src={galaxyIcon} />
          </a>
          <a
            href={`#${ROUTES.WAI.PATH}`}
            className="page-claim-leaderboard-link ab wai-button"
          >
            <img width="103px" src={waiIcon} />
          </a>
        </>
    </div>
  );
};
