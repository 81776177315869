import React from "react";

import { TaskModal } from "../../app-modal/task-modal";
import { VerifyModal } from "../verify-modal";
import { ShareModal } from "../share-modal";
import { TTelegramGroupVerify } from "../../contexts/types";
import { ETask, ITask } from "../type";

interface IProps {
  showVerifyModal: boolean;
  currentTask: ITask | null;
  onVerifyModalClose: () => void;
  onVerifyModalClaim: () => void;
  onTelegramGroupVerify: TTelegramGroupVerify;
  onTaskModalClose: () => void;
  onTaskModalClaim: () => void;
  isJoinedToTelegram: ((type?: ETask | string) => boolean | undefined) | undefined;
  isCurrentTaskShareModal: ITask | null;
  onShareModalClose: () => void;
  onShareModalClaim: () => void;
  payPassMultiplier?: number;
}

export const Modals: React.FC<IProps> = ({
  showVerifyModal,
  currentTask,
  onVerifyModalClose,
  onVerifyModalClaim,
  onTelegramGroupVerify,
  onTaskModalClose,
  onTaskModalClaim,
  isJoinedToTelegram,
  isCurrentTaskShareModal,
  onShareModalClose,
  onShareModalClaim,
  payPassMultiplier
}) => {
  return (
    <>
      <VerifyModal
        showVerifyModal={showVerifyModal}
        currentTask={!currentTask?.share ? currentTask : null}
        onClose={onVerifyModalClose}
        onClaim={onVerifyModalClaim}
        payPassMultiplier={payPassMultiplier}
      />
      <TaskModal
        onTelegramGroupVerify={onTelegramGroupVerify}
        currentTask={
          currentTask?.doubleVerify
            ? null
            : !currentTask?.share
            ? currentTask
            : null
        }
        onClose={onTaskModalClose}
        onClaim={onTaskModalClaim}
        isJoinedToTelegram={isJoinedToTelegram}
        payPassMultiplier={payPassMultiplier}
      />
      <ShareModal
        currentTask={isCurrentTaskShareModal}
        onClose={onShareModalClose}
        onClaim={onShareModalClaim}
        payPassMultiplier={payPassMultiplier}
      />
    </>
  );
};
