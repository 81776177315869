import React from "react";

import { motion } from "framer-motion";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

import { EBooster } from "../types";
import { ERune, TRune } from "../../../hooks/rune/models";

import "./index.scss";
import { BoostersItems } from "./boosters-items";
import { WSkins } from "./boosters-items/w-skins";

interface IProps {
  onOpen: (booster: EBooster) => void;
  multiplierLevel: number;
  chargerLevel: number;
  maximizerLevel: number;
  isMultiplierUsed: boolean;
  isWorkerBoostsUsed: boolean;
  onSetRuneStep?: (runeStep: TRune) => void;
  runeType: ERune;
  counter: {
    start: number;
    end: number;
  };
  setCounter: React.Dispatch<
    React.SetStateAction<{
      start: number;
      end: number;
    }>
  >;
}

enum EToggleBoosters {
  BOOSTERS = "BOOSTERS",
  W_SKINS = "W_SKINS",
}

export const MainBoosters: React.FC<IProps> = ({
  onOpen,
  multiplierLevel,
  chargerLevel,
  maximizerLevel,
  isMultiplierUsed,
  isWorkerBoostsUsed,
  counter,
  setCounter,
  onSetRuneStep,
  runeType,
}) => {
  const [impactOccurred] = useHapticFeedback();
  const [toggle, setToggle] = React.useState<EToggleBoosters>(
    EToggleBoosters.BOOSTERS
  );

  const handleTabChange = (tab: EToggleBoosters) => {
    impactOccurred("heavy");
    setToggle(tab);
  };

  return (
    <div className="main-boosters-wrapper">
      <div className="boosters-toggle-wrapper">
        <h2
          className={`green-color boosters-header-item ${
            toggle === EToggleBoosters.BOOSTERS ? "selected" : ""
          }`}
          onClick={() => handleTabChange(EToggleBoosters.BOOSTERS)}
        >
          <b>Boosters</b>
          {toggle === EToggleBoosters.BOOSTERS && (
            <motion.div className="underline" layoutId="underline" />
          )}
        </h2>
        <h2
          className={`green-color boosters-header-item ${
            toggle === EToggleBoosters.W_SKINS ? "selected" : ""
          }`}
          onClick={() => handleTabChange(EToggleBoosters.W_SKINS)}
        >
          <b>W-Skins</b>
          {toggle === EToggleBoosters.W_SKINS && (
            <motion.div className="underline" layoutId="underline" />
          )}
        </h2>
      </div>
      <div className="main-boosters-boosters-list bebebe">
        {toggle === EToggleBoosters.BOOSTERS ? (
          <BoostersItems
            onOpen={onOpen}
            multiplierLevel={multiplierLevel}
            chargerLevel={chargerLevel}
            maximizerLevel={maximizerLevel}
            isMultiplierUsed={isMultiplierUsed}
            isWorkerBoostsUsed={isWorkerBoostsUsed}
          />
        ) : (
          <WSkins
            counter={counter}
            setCounter={setCounter}
            onSetRuneStep={onSetRuneStep}
            runeType={runeType}
          />
        )}
      </div>
    </div>
  );
};
