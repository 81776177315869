import React, { useMemo, useCallback, memo } from "react";

import { TAppUser, TClaimState } from "../../../contexts/types";
import { SmallCoin } from "../../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_SMALL } from "../../../../consts";
import { formatValues } from "../../../../utils";
import { useCampaignApi } from "./hooks";

import "./index.scss";
import { ICampaign, ECampaign } from "./models";
import { VerifyModal } from "./verify-modal";
import { CheckUidModal } from "./check-uid-modal";
import { PayPassMultiplierComponent } from "../../pay-pass-multiplier";

const campaignLogo = require("./img/campaign-logo.png") as string;
const checkedImg = require("./img/checked.svg").default as string;
const completedTasksImg = require("./img/completed.png") as string;

interface IProps {
  user: TAppUser;
  setClaimState?: React.Dispatch<React.SetStateAction<TClaimState | undefined>>;
}

export const OkxCampaign: React.FC<IProps> = memo(({ user, setClaimState }) => {
  const {
    activeTask,
    isLoading,
    isAllTasksDone,
    tasksToDisplay,
    onModalOpen,
    completedTasks,
    allTasks,
    setActiveTask,
    onClaimTask,
    onCheckUidModalOpen,
    isCampaignTaskCompleted,
    payPassMultiplier,
  } = useCampaignApi(user, setClaimState);

  const onTaskClick = useCallback(
    (task: ICampaign) => {
      if (task.is_done) return;

      if (task.type === ECampaign.OKX_CAMPAIGN) {
        onCheckUidModalOpen(task);
      } else if (task.type === ECampaign.JOIN_NEW_TG_CHANNEL) {
        onModalOpen(task);
      } else {
        onModalOpen(task);
      }
    },
    [onCheckUidModalOpen, onModalOpen]
  );

  const renderedTasks = useMemo(
    () =>
      tasksToDisplay.map((task) => (
        <div
          className="collab-list-item"
          onClick={() => onTaskClick(task)}
          key={task.type}
          style={{
            position: "relative",
          }}
        >
          <img
            className="collab-item-icon"
            src={task?.is_done ? checkedImg : task.icon}
            alt=""
            width="70px"
            height="70px"
          />
          <div className="collab-item-content">
            <div className="task-item-reward">
              <div className="task-item-info-stack">
                <div className="white-color">{task.title}</div>
                {!task?.is_done && (
                  <div className="collab-item-desc">
                    <SmallCoin
                      width={BALANCE_COIN_SIZE_SMALL}
                      height={BALANCE_COIN_SIZE_SMALL}
                    />
                    <p className="white-color">+{formatValues(task.reward)}</p><PayPassMultiplierComponent payPassMultiplier={payPassMultiplier} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )),
    [tasksToDisplay, onTaskClick]
  );

  return (
    <div
      className="task-wrapper"
      style={{ display: "flex", justifyContent: "space-between" }}
    >
      <CheckUidModal
        currentTask={
          activeTask?.type === ECampaign.OKX_CAMPAIGN ? activeTask : null
        }
        onClose={() => setActiveTask(null)}
        onClaimTask={onClaimTask}
        isCompleted={isCampaignTaskCompleted}
        payPassMultiplier={payPassMultiplier}
      />
      <VerifyModal
        currentTask={
          (activeTask?.type === ECampaign.JOIN_NEW_TG_CHANNEL || activeTask?.type === ECampaign.OKX_IN_X) ? activeTask : null
        }
        onClose={() => setActiveTask(null)}
        onClaim={
          activeTask ? () => onClaimTask({ task: activeTask }) : undefined
        }
        isLoading={isLoading}
        showVerifyModal={activeTask?.type === ECampaign.JOIN_NEW_TG_CHANNEL || activeTask?.type === ECampaign.OKX_IN_X}
        payPassMultiplier={payPassMultiplier}
        
      />
      <div
        className="task-list-main-wrapper bebebe"
        style={{ height: isAllTasksDone ? "100%" : "" }}
      >
        <div className="task-list-wrapper">
          {isAllTasksDone ? (
            <div className="collab-done">
              <img width="115px" src={completedTasksImg} alt="" />
              <div className="collab-done-desc">
                <p>Congratulations!</p>
                <p>You have successfully</p>
                <p>completed all tasks!</p>
              </div>
            </div>
          ) : (
            renderedTasks
          )}
        </div>
        <div className="collab-footer">
          {!isAllTasksDone && (
            <div>
              Tasks completed:{" "}
              <b className="collab-footer-counter ab">
                {completedTasks}/{allTasks}
              </b>
            </div>
          )}
        </div>
      </div>
      <img
        style={{ display: "flex", margin: "0 auto" }}
        width="240px"
        src={campaignLogo}
        alt=""
      />
    </div>
  );
});
