import { TLevel } from "./models";

export const INVITE_MATE_URL = process.env.REACT_APP_INVITE_MATE_URL;
export const MATES_MULTIPLIER = 3;
export const INVITE_THREE_MATES_REWARD = 500_000;

export const LEVELS: TLevel = {
  1: {
    level: 1,
    reward: {
      old: 2_500,
    },
    premiumReward: {
      old: 30_000,
    },
    mates: 10,
    rewards: 1,
    levelRewards: {
      old:  100_000,
    },
  },
  2: {
    level: 2,
    reward: {
      old: 5_000,
    },
    premiumReward: {
      old: 50_000,
    },
    mates: 35,
    rewards: 1.5,
    levelRewards: {
      old: 200_000,
    },
  },
  3: {
    level: 3,
    reward: {
      old: 7_500,
    },
    premiumReward: {
      old: 75_000,
    },
    mates: 75,
    rewards: 2,
    levelRewards: {
      old: 400_000,
    },
  },
  4: {
    level: 4,
    reward: {
      old: 10_000,
    },
    premiumReward: {
      old: 100_000,
    },
    mates: 150,
    rewards: 2.5,
    levelRewards: {
      old: 800_000,
    },
  },
  5: {
    level: 5,
    reward: {
      old: 12_500,
    },
    premiumReward: {
      old: 125_000,
    },
    mates: 300,
    rewards: 3,
    levelRewards: {
      old: 1_600_000,
    },
  },
  6: {
    level: 6,
    reward: {
      old: 15_000,
    },
    premiumReward: {
      old: 150_000,
    },
    mates: 600,
    rewards: 3.5,
    levelRewards: {
      old: 3_200_000,
    },
  },
  7: {
    level: 7,
    reward: {
      old: 17_500,
    },
    premiumReward: {
      old: 175_000,
    },
    mates: 1_200,
    rewards: 4,
    levelRewards: {
      old: 6_400_000,
    },
  },
  8: {
    level: 8,
    reward: {
      old: 20_000,
    },
    premiumReward: {
      old: 200_000,
    },
    mates: 2_400,
    rewards: 4.5,
    levelRewards: {
      old: 12_800_000,
    },
  },
  9: {
    level: 9,
    reward: {
      old: 22_500,
    },
    premiumReward: {
      old: 225_000, 
    },
    mates: 4800,
    rewards: 5,
    levelRewards: {
      old: 25_600_000,
    },
  },
  10: {
    level: 10,
    reward: {
      old: 25_000,
    },
    premiumReward: {
      old: 250_000,
    },
    mates: 8_000,
    rewards: 5.5,
    levelRewards: {
      old: 51_200_000,
    },
  },
  11: {
    level: 11,
    reward: {
      old: 27_000,
    },
    premiumReward: {
      old: 275_000,
    },
    mates: 16_000,
    rewards: 6,
    levelRewards: {
      old: 102_400_000,
    },
  },
};

export const FIRST_LEVEL = LEVELS[0];
export const LAST_LEVEL = Object.values(LEVELS).at(-1)?.level;