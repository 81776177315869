import React from "react";

import { CSSTransition } from "react-transition-group";

const countdownModalImg = require("./img/countdown-modal-icon.png") as string;

interface IProps {
  show: boolean;
  onClose: () => void;
}

export const CountdownModal: React.FC<IProps> = ({ show, onClose }) => {
  return (
    <CSSTransition in={show} timeout={500} classNames="fade" unmountOnExit>
      <div className="overlay app-modal-root">
        <div
          className={`app-modal-content-wrapper task-modal-wrapper`}
          onClick={(e) => e.stopPropagation()}
        >
          <div className="close-modal ab" onClick={onClose}>
            x
          </div>
          <div className="app-modal-content task-modal-content">
            <div className="task-modal-desc">
              <div
                className=""
                style={{ background: "transparent" }}
              >
                <img src={countdownModalImg} style={{ width: "75px" }} alt="" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "12px",
                  textAlign: "center",
                }}
              >
                <p style={{ color: "#fff" }}>
                  <p className="ab">Staking will close on November 20</p> - make
                  the most of it while you can! 
                </p>
                <p style={{ color: "#fff" }}>Other features will be gradually
                disabled afterward until mining is fully wrapped up⏳</p>
                <div style={{ color: "#fff" }}>
                  <p>
                    <p>P.S. You can still stake on November 20, and all your progress will be counted
                    </p>
                  </p>
                </div>
              </div>
            </div>
            <div className="task-modal-button-wrapper">
              <button onClick={onClose} className="app-modal-content-button">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};
