import React from 'react';
import { GOLD_COLOR, PAY_PASS_MULTIPLIER, PAY_PASS_MULTIPLIER_LIGHT } from '../../../consts';

interface IProps {
    payPassMultiplier?: number;
}

export const PayPassMultiplierComponent: React.FC<IProps> = ({payPassMultiplier}) => {
    const s = {
        color: GOLD_COLOR,
    }

    const isPayPass = () => {
        if (payPassMultiplier === PAY_PASS_MULTIPLIER) {
            return PAY_PASS_MULTIPLIER
        } else if (payPassMultiplier === PAY_PASS_MULTIPLIER_LIGHT) {
            return PAY_PASS_MULTIPLIER_LIGHT
        }
        
        return null;
    }

    
    return isPayPass() ? <p style={s}>x{isPayPass()}</p> : null;
}