import React from "react";

import { TClaimState } from "../../contexts/types";
import { useUpdateUserExternalApi } from "../../../hooks";
import { RUNE_REWARD } from "../../../consts";
import { TSkins, TTask } from "../../feature/users/models";

import { ERune, TRune, TStepModalContent } from "./models";

// images
const wIcon = require("./img/w-icon.png") as string;
const cIcon = require("./img/c-icon.png") as string;
const oIcon = require("./img/o-icon.png") as string;
const iIcon = require("./img/i-icon.png") as string;
const nIcon = require("./img/n-icon.png") as string;

export const useRuneApi = ({
  tasks,
  setClaimState,
  claimState,
}: {
  tasks?: TTask;
  setClaimState?: React.Dispatch<React.SetStateAction<TClaimState | undefined>>;
  claimState?: TClaimState;
}) => {
  const [updateUserExternalApi, updateUserState] = useUpdateUserExternalApi();

  const [showRuneModal, setShowRuneModal] = React.useState(false);
  const [rune, setRune] = React.useState<TRune | null>(null);

  React.useEffect(() => {
    if (tasks?.rune) {
      // @ts-ignore
      setRune(tasks.rune);
    }
  }, [tasks?.rune]);

  const stepRuneModalContent: TStepModalContent = React.useMemo(() => {
    // @ts-ignore
    const step = rune?.step as never as ERune;

    switch (step) {
      case ERune.START:
        return {
          title: "Rune Collector Quest",
          message: "Well done, Mate! You’ve unlocked the ancient \"W\" rune, the first piece of a powerful artifact. Your quest begins: find the remaining runes hidden across W-Coin App and spell out \"WCOIN\". Only the worthy will succeed. Good luck!",
          button: "Start Quest",
          icon: wIcon,
        };
      case ERune.C:
        return {
          title: "Rune Found!",
          message: "Great job! You’ve found the \"C\" rune. Only 3 more to go!",
          button: "Continue",
          icon: cIcon,
        };
      case ERune.O:
        return {
          title: "Rune Found!",
          message: "Awesome! You’ve discovered the \"O\" rune. Just 2 more left!",
          button: "Continue",
          icon: oIcon,
        };
      case ERune.I:
        return {
          title: "Rune Found!",
          message: "Excellent! You’ve located the \"I\" rune. Only 1 more to go!",
          button: "Continue",
          icon: iIcon,
        };
      case ERune.N:
        return {
          title: "Rune Found!",
          message:
            "Fantastic! You’ve collected the \"N\" rune. You’ve now gathered all the runes!",
          button: "Claim Reward",
          icon: nIcon,
        };
      case ERune.DONE:
        return {
          title: "Quest Complete!",
          message: `Congratulations, mate! You’ve successfully collected all the runes and spelled out \"WCOIN\". Here is your reward!`,
          button: "Find out the secret",
        };
      default:
        return null;
    }
  }, [rune?.step]);

  const handleShowRuneModal = (value: boolean) => {
    setShowRuneModal(value);
  };

  const handleSetRuneStep = (runeStep: TRune, data?: {
    balance: number;
    skins: TSkins | undefined;
    tasks: TTask | undefined;
}) => {
    setRune(runeStep);
    handleShowRuneModal(true);

    if (claimState && setClaimState) {
      let newData = data ? {
        ...data,
        tasks: {
          ...claimState.tasks,
          rune: { step: runeStep.step, completed: runeStep.completed },
        },
      }  : {
        tasks: {
          ...claimState.tasks,
          rune: { step: runeStep.step, completed: runeStep.completed },
        },
        balance: runeStep.completed
          ? (claimState.balance || 0) + RUNE_REWARD
          : claimState.balance || 0,
      };

      updateUserExternalApi({ ...newData })
        .then(() => {
          // @ts-ignore
          setClaimState((prevState) => ({
            ...(prevState || {}),
            ...newData,
          }));
        })
        .catch((e: any) => {
          console.warn(e);
        });
    }
  };

  return {
    showRuneModal,
    onShowRuneModal: handleShowRuneModal,
    onSetRuneStep: handleSetRuneStep,
    stepRuneModalContent,
    rune,
    isRuneUpdating: updateUserState.isLoading,
  };
};
