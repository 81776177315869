import React from "react";

import { useOpenTelegramLink } from "../../../hooks";

export const CommunityButton: React.FC = () => {
  const { onOpenTelegramLink } = useOpenTelegramLink();

  return (
    <button
      onClick={() => onOpenTelegramLink()}
      className={`app-modal-content-button`}
    >
      Follow W-Community
    </button>
  );
};
