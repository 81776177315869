import React, { useRef } from "react";

// @ts-ignore
import { useSpring, animated } from "react-spring";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import ProgressBar from "@ramonak/react-progress-bar";
import { Button } from "react-bootstrap";
import anime from "animejs";

import { isPayPasPurchased } from "../../../utils";

import { ClaimCoin } from "../icons/claim-coin";
import "./styles.css";
import Wlogo from "./w-logo-icon.png";
import { TiltAnimation } from "./tilt-animation";

// images
const lightningIcon = require("../img/lightning.png") as string;

interface IProps {
  onClick: () => void;
  energy: number;
  maxEnergy: number;
  clickMultiplier: number;
  disableBtn: boolean;
  claimIcon: string;
  isSpinnerSkin?: boolean;
  payPassMultiplier?: number;
  showAnimation: boolean;
}

export const ClaimButton: React.FC<IProps> = ({
  onClick,
  energy,
  maxEnergy,
  clickMultiplier,
  disableBtn,
  claimIcon,
  isSpinnerSkin,
  payPassMultiplier,
  showAnimation
}) => {
  const [impactOccurred] = useHapticFeedback();

  const createDoubleTapPreventer = (timeout_ms: number) => {
    let dblTapTimer = 0;
    let dblTapPressed = false;

    return function (e: TouchEvent) {
      clearTimeout(dblTapTimer);
      if (dblTapPressed) {
        e.preventDefault();
        dblTapPressed = false;
      } else {
        dblTapPressed = true;
        // @ts-ignore
        dblTapTimer = setTimeout(() => {
          dblTapPressed = false;
        }, timeout_ms);
      }
    };
  };

  React.useEffect(() => {
    document.body.addEventListener(
      "touchstart",
      createDoubleTapPreventer(500),
      { passive: false }
    );
  }, []);

  const [clicks, setClicks] = React.useState<any>([]);
  const [[manualTiltAngleX, manualTiltAngleY], setManualTiltAngle] =
    React.useState([0, 0]);

  const vibrate = () => {
    const navigatorVibrate =
      navigator.vibrate ||
      // @ts-ignore
      navigator.webkitVibrate ||
      // @ts-ignore
      navigator.mozVibrate ||
      // @ts-ignore
      navigator.msVibrate;

    if (navigatorVibrate) {
      navigatorVibrate.call(navigator, [15]);
    } else {
      impactOccurred("light");
    }
  };

  const logoContainerRef = useRef<HTMLDivElement>(null);

  const scatterLogos = (x: number, y: number) => {
    if (!logoContainerRef.current) return;

    const numberOfLogos = anime.random(3, 4);

    for (let i = 0; i < numberOfLogos; i++) {
      const newLogo = document.createElement("img");
      newLogo.src = Wlogo;
      newLogo.classList.add("logo");
      newLogo.draggable = false;

      newLogo.style.left = `${x - 25}px`;
      newLogo.style.top = `${y - 25}px`;

      const initialRotation = anime.random(-180, 180);
      newLogo.style.transform = `rotate(${initialRotation}deg)`;

      logoContainerRef.current.appendChild(newLogo);

      requestAnimationFrame(() => {
        anime({
          targets: newLogo,
          translateX: () => anime.random(-200, 200),
          translateY: () => anime.random(-200, 200),
          opacity: [1, 0],
          duration: 1000,
          easing: "easeOutExpo",
          complete: () => {
            newLogo.remove();
          },
        });
      });
    }
  };

  const handleTouchStart = (event: any) => {
    vibrate();

    const touch = event.changedTouches[0];
    const { clientX, clientY } = touch;
    const buttonRect = event.target.getBoundingClientRect();
    const percentX = (clientX - buttonRect.left) / buttonRect.width;
    const percentY = (clientY - buttonRect.top) / buttonRect.height;
    let tiltAngleX = (percentY - 0.5) * 20;
    let tiltAngleY = (percentX - 0.5) * 20 * -1;
    setManualTiltAngle([tiltAngleX, tiltAngleY]);

    onClick();

    scatterLogos(clientX, clientY);
  };

  const handleTouchEnd = (event: any) => {
    event?.preventDefault?.();

    const { clientX, clientY } = event.changedTouches[0];

    setClicks((prevClicks: any) => [
      ...prevClicks,
      { id: Math.random(), x: clientX, y: clientY },
    ]);

    setTimeout(() => {
      setClicks((prevClicks: any) => prevClicks.slice(1));
    }, 1000);

    setTimeout(() => {
      setManualTiltAngle([0, 0]);
    }, 100);
  };

  const [rotation, setRotation] = React.useState(0);
  const [startRotation, setStartRotation] = React.useState(false);

  const { transform } = useSpring({
    from: { transform: `rotate(${rotation}deg)` },
    to: {
      transform: `rotate(${startRotation ? rotation + 360 : rotation}deg)`,
    },
    config: {
      tension: 150,
      friction: 30,
    },
    onRest: () => {
      if (startRotation) {
        setRotation((prevRotation) => prevRotation + 360);
        setStartRotation(false);
      }
    },
  });

  const timerId = React.useRef<number | null>(null);

  const handleClick = () => {
    setStartRotation(true);
    setRotation((prevRotation) => prevRotation + 360);

    if (timerId.current) {
      clearTimeout(timerId.current);
    }

    // @ts-ignore
    timerId.current = setTimeout(() => {
      setStartRotation(false);
    }, 5000);
  };

  const onClickWithoutAnimation = () => {
    vibrate();
    onClick();
  }

  return (
    <div className="claim-button-root">
      {isPayPasPurchased(payPassMultiplier) && showAnimation ? (
        <div className="logo-container" ref={logoContainerRef}></div>
      ) : null}
      {isSpinnerSkin ? (
        <animated.div
          style={{ transform }}
          onTouchStart={disableBtn ? undefined : handleClick}
        >
          <div className="box claim claim-button-wrapper">
            <Button
              className={`claim-button ${
                disableBtn ? "disabled" : ""
              } disable-dbl-tap-zoom`}
              onTouchStart={showAnimation ?  handleTouchStart : onClickWithoutAnimation}
              onTouchEnd={showAnimation ?  handleTouchEnd : undefined}
            >
              <TiltAnimation
                manualTiltAngleX={manualTiltAngleX}
                manualTiltAngleY={manualTiltAngleY}
              >
                <ClaimCoin claimIcon={claimIcon} />
              </TiltAnimation>
            </Button>
          </div>
        </animated.div>
      ) : (
        <Button
          className={`claim-button ${
            disableBtn ? "disabled" : ""
          } disable-dbl-tap-zoom`}
          onTouchStart={showAnimation ? handleTouchStart : onClickWithoutAnimation}
          onTouchEnd={showAnimation ?  handleTouchEnd : undefined}
        >
          <TiltAnimation
            manualTiltAngleX={manualTiltAngleX}
            manualTiltAngleY={manualTiltAngleY}
          >
            <ClaimCoin claimIcon={claimIcon} />
          </TiltAnimation>
        </Button>
      )}
      {showAnimation &&
        clicks.map((click: any) => (
          <div
            key={click.id}
            className={`flyAndFade ab ${
              isPayPasPurchased(payPassMultiplier)
                ? "fly-and-fade-gold-color"
                : "fly-and-fade-basic-color"
            }`}
            style={{ position: "fixed", left: click.x, top: click.y }}
          >
            +{clickMultiplier}
          </div>
        ))}
      <ProgressBar
        completed={energy}
        maxCompleted={maxEnergy}
        bgColor="#A385EA"
        baseBgColor="#E1DBEF"
        height="13px"
        labelSize="0"
        width="100%"
        transitionTimingFunction="linear"
        className="mates-progress claim-progress"
      />
      <div className="claim-charger main-color">
        {energy === 0 ? "🪫" : <img width="18px" src={lightningIcon} />}
        <div className="claim-charger-count">
          <h1 className="claim-charger-count-text">{energy}</h1>
          <h1 className="claim-charger-count-text">/</h1>
          <h1 className="claim-charger-count-text">{maxEnergy}</h1>
        </div>
      </div>
    </div>
  );
};
