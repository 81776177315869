import React from "react";

import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

import { Links } from "../links";
import { ROUTES } from "../../routers";
const stackingIcon = require("../img/staking.png") as string;
const rocketIcon = require("../img/rocket.png") as string;
const tasksIcon = require("../img/tasks.png") as string;
const matesIcon = require("../img/mates.png") as string;

interface IProps {
  onGoToDashboard: () => void;
  activeTaskCount: number;
}

export const Footer: React.FC<IProps> = ({
  onGoToDashboard,
  activeTaskCount,
}) => {
  const [impactOccurred] = useHapticFeedback();

  return (
    <div className="bottom-nav">
      <Links
        path={`#${ROUTES.MATES.PATH}`}
        icon={matesIcon}
        label="Mates"
        width={30}
        height={30}
      />
      <Links
        path={`#${ROUTES.TASKS.PATH}`}
        icon={tasksIcon}
        label="Tasks"
        activeTaskCount={activeTaskCount}
        width={30}
        height={30}
      />
      <Links
        path={`#${ROUTES.DASHBOARD.PATH}`}
        icon={stackingIcon}
        label="Staking"
        onClick={() => {
          onGoToDashboard();
          impactOccurred("heavy");
        }}
        width={30}
        height={30}
      />
      <Links
        path={`#${ROUTES.BOOSTERS.PATH}`}
        icon={rocketIcon}
        label="Boost"
        width={30}
        height={30}
      />
    </div>
  );
};
