import { TagDescription } from "@reduxjs/toolkit/query";
import CryptoJS from "crypto-js";

import { EAPITagType } from "../../../enums";
import {
  baseApi,
  externalBaseApi,
  JWT_TOKEN_KEY,
  legendBaseApi,
  okxCheckUidBaseApi,
} from "../../../services";
import { getClientTimestamp } from "../../../utils";
import { EWaiUpgradeType } from "../../wai/models";
import { ITask } from "../../page-tasks/type";

import {
  IUserPassiveIncomeResponse,
  TAuthArgs,
  TAuthResponse,
  TClaimStakingArgs,
  TClaimStakingResponse,
  TCreateStakeArgs,
  TCreateStakeResponse,
  TLeaderboard,
  TMates,
  TMatesGetRewardsResponse,
  TPayPassGenerateInvoice,
  TRegistrationApp,
  TStats,
  TUpdateClientArgs,
  TUser,
  TValidateUserInGroup,
  TValidateUserInGroupArgs,
} from "./models";
import { ILeaderboardApiResponse, TLeague } from "./wai.models";

localStorage.removeItem(JWT_TOKEN_KEY);

const getInitData = () => {
  return (
    // @ts-ignore
    process.env.REACT_APP_INIT_DATA_HASH ?? window.Telegram.WebApp.initData
  );
};

const clientApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    auth: builder.mutation<TAuthResponse, TAuthArgs>({
      query: (queryArg) => {
        // @ts-ignore
        return {
          url: `/api/auth/local`,
          method: "POST",
          body: {
            identifier:
              process.env.REACT_APP_DEVELOPMENT_ONLY_TEST_USER_EMAIL ??
              queryArg.username,
            password:
              process.env.REACT_APP_DEVELOPMENT_ONLY_TEST_USER_PASSWORD ??
              queryArg.password,
          },
        };
      },
      transformResponse: (response: TAuthResponse) => {
        localStorage.setItem(JWT_TOKEN_KEY, response.jwt);
        return response;
      },
      // @ts-ignore
      invalidatesTags: [EAPITagType.USERS],
    }),
    registrationApp: builder.mutation<TAuthResponse, TRegistrationApp>({
      query: (queryArg) => {
        const hash = getInitData();

        return {
          url: `api/users-permissions/users/registration-app?${hash}`,
          method: "POST",
          body: queryArg,
        };
      },
      transformResponse: (response: TAuthResponse) => {
        return response;
      },
      invalidatesTags: [EAPITagType.NOTIFY_USERS],
    }),
    getTasks: builder.query<ITask[], void>({
      query: () => {
        return {
          url: "/api/tasks",
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      transformResponse: (response: ITask[]) => {
        return response;
      },
      providesTags: [EAPITagType.TASKS],
    }),
    getLeaderboard: builder.query<TLeaderboard, void>({
      query: () => ({
        url: "/api/users-permissions/users/leaderboard",
        method: "GET",
      }),
      transformResponse: (response: TLeaderboard) => {
        return response;
      },
      keepUnusedDataFor: 5,
      providesTags: [EAPITagType.LEADERBOARD],
    }),
    getValidateUserInGroup: builder.query<
      TValidateUserInGroup,
      TValidateUserInGroupArgs
    >({
      query: (queryArg) => {
        return {
          url: `/api/users-permissions/users/validate-user-in-group/${queryArg.resource}`,
          method: "GET",
        };
      },
      transformResponse: (response: TValidateUserInGroup) => {
        return response;
      },
      keepUnusedDataFor: 5,
      providesTags: [EAPITagType.VALIDATE_USER_IN_GROUP],
    }),
    getStats: builder.query<TStats[], void>({
      query: () => {
        return {
          url: "/api/stat",
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      providesTags: [EAPITagType.STATS],
    }),
    getMates: builder.query<TMates, void>({
      query: () => {
        const clientTimestamp = getClientTimestamp();
        const hash = getInitData();

        return {
          url: `/api/users-permissions/users/get-mates?timestamp=${clientTimestamp}}&hash=${hash}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      transformResponse: (response: TMates) => {
        return response;
      },
      providesTags: [EAPITagType.MATES_LIST],
    }),
    getMatesGetRewards: builder.query<TMatesGetRewardsResponse, void>({
      query: () => {
        const clientTimestamp = getClientTimestamp();
        const hash = getInitData();

        return {
          url: `/api/users-permissions/users/claim-mates-reward?timestamp=${clientTimestamp}}&hash=${hash}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 5,
      transformResponse: (response: TMatesGetRewardsResponse) => {
        return response;
      },
      providesTags: [EAPITagType.MATES_GET_REWARDS],
    }),
    createStake: builder.mutation<TCreateStakeResponse, TCreateStakeArgs>({
      query: (queryArg) => {
        const clientTimestamp = getClientTimestamp();
        const hash = getInitData();

        return {
          url: `/api/users-permissions/users/stake/create?timestamp=${clientTimestamp}}&hash=${hash}`,
          method: "POST",
          body: {
            days: queryArg.days,
            stake_percent_from_balance: queryArg.stake_percent_from_balance,
          },
        };
      },
      transformResponse: (response: TCreateStakeResponse) => {
        return response;
      },
      invalidatesTags: [EAPITagType.STAKE_CREATE],
    }),
    claimStaking: builder.mutation<TClaimStakingResponse, TClaimStakingArgs>({
      query: (queryArg) => {
        const clientTimestamp = getClientTimestamp();
        const hash = getInitData();

        return {
          url: `/api/users-permissions/users/stake/claim?timestamp=${clientTimestamp}}&hash=${hash}`,
          method: "POST",
          body: {
            timestamp: queryArg.timestamp,
          },
        };
      },
      transformResponse: (response: TClaimStakingResponse) => {
        return response;
      },
      invalidatesTags: [EAPITagType.CLAIM_STAKING],
    }),
    getWai: builder.query<IUserPassiveIncomeResponse, void>({
      query: () => {
        const clientTimestamp = Math.floor(Date.now() / 1000);
        // @ts-ignore
        const hash = getInitData();

        return {
          url: `/api/users-permissions/passive-income/me?timestamp=${clientTimestamp}}&hash=${hash}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          keepUnusedDataFor: 5,
          providesTags: [EAPITagType.USER_PASSIVE_INCOME],
        };
      },
      transformResponse: (response: IUserPassiveIncomeResponse) => {
        return response;
      },
      providesTags: [EAPITagType.USERS],
    }),
    getWaiClaim: builder.query<IUserPassiveIncomeResponse, void>({
      query: (queryArg) => {
        const clientTimestamp = Math.floor(Date.now() / 1000);
        // @ts-ignore
        const hash = getInitData();

        return {
          url: `/api/users-permissions/passive-income/claim?timestamp=${clientTimestamp}}&hash=${hash}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          keepUnusedDataFor: 5,
          providesTags: [EAPITagType.USER_PASSIVE_INCOME_CLAIM],
        };
      },
      transformResponse: (response: IUserPassiveIncomeResponse) => {
        return response;
      },
      // @ts-ignore
      providesTags: [EAPITagType.USERS],
    }),
    getWaiIncreaseLevel: builder.query<
      IUserPassiveIncomeResponse,
      { type: EWaiUpgradeType }
    >({
      query: (queryArg) => {
        const clientTimestamp = Math.floor(Date.now() / 1000);
        // @ts-ignore
        const hash = getInitData();

        return {
          url: `/api/users-permissions/passive-income/increase-level/${queryArg.type}?timestamp=${clientTimestamp}}&hash=${hash}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          keepUnusedDataFor: 5,
          providesTags: [EAPITagType.USER_PASSIVE_INCOME_INCREASE_LEVEL],
        };
      },
      transformResponse: (response: IUserPassiveIncomeResponse) => {
        return response;
      },
      providesTags: [EAPITagType.USERS],
    }),
    getWaiLeaderboardByLeague: builder.query<
      ILeaderboardApiResponse,
      { league: TLeague }
    >({
      query: (queryArg) => {
        const clientTimestamp = Math.floor(Date.now() / 1000);
        // @ts-ignore
        const hash = getInitData();
        return {
          url: `/api/users-permissions/passive-income/leaderboard/${queryArg.league}?timestamp=${clientTimestamp}&hash=${hash}`,
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          keepUnusedDataFor: 5,
          providesTags: [EAPITagType.WAI_LEADERBOARD],
        };
      },
      transformResponse: (response: ILeaderboardApiResponse) => {
        return response;
      },
      providesTags: [EAPITagType.USERS],
    }),
  }),
});

export const {
  useAuthMutation,
  useRegistrationAppMutation,
  useGetTasksQuery,
  useGetStatsQuery,
  useGetLeaderboardQuery,
  useLazyGetValidateUserInGroupQuery,
  useLazyGetMatesGetRewardsQuery,
  useGetMatesQuery,
  useCreateStakeMutation,
  useClaimStakingMutation,
  useGetWaiQuery,
  useLazyGetWaiClaimQuery,
  useLazyGetWaiIncreaseLevelQuery,
  useLazyGetWaiLeaderboardByLeagueQuery,
} = clientApi;

type TVerifyTaskArgs = {
  telegramUser: string;
  taskName: string;
};

type TVerifyTaskResponse = {
  status: "success" | "fail" | "error";
  message: string;
  telegramUser: string;
  taskName: string;
  isFinished: boolean;
};

const legendApi = legendBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    verifyTask: builder.mutation<TVerifyTaskResponse, TVerifyTaskArgs>({
      query: ({ telegramUser, taskName }) => ({
        url: "/verifyTask",
        method: "POST",
        body: { telegramUser, taskName },
        credentials: "omit",
      }),
      transformResponse: (response: TVerifyTaskResponse) => {
        return response;
      },
      // @ts-ignore
      invalidatesTags: [EAPITagType.LEGENDS],
    }),
  }),
});

export const { useVerifyTaskMutation } = legendApi;

const externalApi = externalBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    updateRedeemCode: builder.mutation<boolean, { code: string }>({
      query: ({ code }) => ({
        url: `/api/integrations/redeem-code`,
        method: "POST",
        body: { code },
      }),
      transformResponse: (response: boolean) => {
        return response;
      },
      // @ts-ignore
      invalidatesTags: [EAPITagType.EXTERNAL_API],
    }),
    updateUserExternalApi: builder.mutation<
      TUser,
      {
        encryptedData: string;
        encryptedSymmetricKey: string;
        iv: string;
        authTag: string;
        data: TUpdateClientArgs;
      }
    >({
      query: ({ data, encryptedData, encryptedSymmetricKey, iv, authTag }) => {
        return {
          url: "/api/external-api/user/update",
          method: "POST",
          body: { encryptedData, data, encryptedSymmetricKey, iv, authTag },
        };
      },

      invalidatesTags: [
        EAPITagType.EXTERNAL_API_UPDATE_USER as TagDescription<EAPITagType.EXTERNAL_API>,
      ],
    }),
    getPayPassGenerateInvoice: builder.mutation<TPayPassGenerateInvoice, void>({
      query: () => {
        return {
          url: "/api/external-api/pay-pass/generate-invoice",
          method: "POST",
        };
      },
      transformResponse: (response: TPayPassGenerateInvoice) => {
        return response;
      },
    }),
    getPayPassLiteGenerateInvoice: builder.mutation<TPayPassGenerateInvoice, void>({
      query: () => {
        return {
          url: "/api/external-api/pay-pass-lite/generate-invoice",
          method: "POST",
        };
      },
      transformResponse: (response: TPayPassGenerateInvoice) => {
        return response;
      },
    }),
  }),
});

export const {
  useUpdateRedeemCodeMutation,
  useUpdateUserExternalApiMutation,
  useGetPayPassGenerateInvoiceMutation,
  useGetPayPassLiteGenerateInvoiceMutation,
} = externalApi;

const okxCheckUidApi = okxCheckUidBaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCheckUid: builder.query<any, { uid: string }>({
      query: ({ uid }) => {
        const generateSignature = (
          method: string,
          requestPath: string,
          secret: string
        ) => {
          const timestamp = new Date().toISOString();
          const prehashString = `${timestamp}${method}${requestPath}`;
          const signature = CryptoJS.HmacSHA256(prehashString, secret).toString(
            CryptoJS.enc.Base64
          );
          return { signature, timestamp };
        };

        const method = "GET";
        const requestPath = `/api/v5/affiliate/invitee/detail?uid=${uid}`;
        const { signature, timestamp } = generateSignature(
          method,
          requestPath,
          "FD5A3A96B7D72897B0B74BC953B608DE"
        );

        return {
          url: requestPath,
          method,
          headers: {
            "OK-ACCESS-KEY": "9acf5a7a-052f-4183-b29d-b14b4ad6da45",
            "OK-ACCESS-SIGN": signature,
            "OK-ACCESS-PASSPHRASE": "W-coin20244",
            "OK-ACCESS-TIMESTAMP": timestamp,
          },
        };
      },
      keepUnusedDataFor: 5,
      transformResponse: (response: any[]) => response,
      providesTags: [EAPITagType.OKX_CHECK_UID],
    }),
  }),
});

export const { useLazyGetCheckUidQuery } = okxCheckUidApi;
