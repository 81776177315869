import React from "react";
import { SmallCoin } from "../../../page-claim/icons/small-coin";
import { BALANCE_COIN_SIZE_MEDIUM } from "../../../../consts";
import { formatValues } from "../../../../utils";
import { PayPassMultiplierComponent } from "../../../page-tasks/pay-pass-multiplier";

interface IProps {
  reward?: number;
  payPassMultiplier?: number;
  modalLabel?: string
  isDone: boolean;
}

export const Price: React.FC<IProps> = ({ reward, payPassMultiplier, modalLabel, isDone }) => {
  return (
    <>
      <div className="task-modal-reward ab">
        <SmallCoin
          width={BALANCE_COIN_SIZE_MEDIUM}
          height={BALANCE_COIN_SIZE_MEDIUM}
        />
        <p>+{formatValues(reward)}</p>{" "}
        <PayPassMultiplierComponent payPassMultiplier={payPassMultiplier} />
      </div>
      <div>
      <p className="task-modal-received">Received</p>
      <p className="task-modal-received">
          {modalLabel && !isDone ? "Complete task to get" : ''}
        </p>
      </div>
    </>
  );
};
