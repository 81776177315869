import {
  createContext,
  useMemo,
} from "react";
import { FireWorksAnimation } from "../fire-works-animation";
import React from "react";

export type TConfig = {
  duration: number;
  blockCount: number;
  blockImage: string;
  blockSpeedRange?: { min: number; max: number };
  rotationRange?: { min: number; max: number };
  spawnSuccessChance?: number;
};

export type TContext = {
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const LottieAnimationContext = createContext<TContext>({
  setIsVisible: undefined,
});

export const LottieAnimationProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const [isVisible, setIsVisible] = React.useState(false);

  const handleOnClose = React.useCallback(() => {
    setIsVisible(false);
  }, [])
  
  const ctx: TContext = useMemo(
    () => ({
      setIsVisible,
    }),
    [setIsVisible]
  );

  return (
    <LottieAnimationContext.Provider value={ctx}>
       <>
      <FireWorksAnimation
        isVisible={!!isVisible}
        onChange={handleOnClose}
      />
     {children}</>
    </LottieAnimationContext.Provider>
  );
};
