import React from "react";
import "./styles.scss"; // Optional: For styling

interface IProps {
  isOn: boolean;
  onClick: () => void;
}

export const Switcher: React.FC<IProps> = ({ isOn, onClick }) => {
  return (
    <div className={`switcher ${isOn ? "on" : "off"}`} onClick={onClick}>
      <div className="switcher-thumb" />
    </div>
  );
};