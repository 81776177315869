import React from "react";

import { useHapticFeedback, useWebApp } from "@vkruglikov/react-telegram-web-app";

import { TELEGRAM_LINK } from "../../../../consts";
import { useUpdateUserExternalApi } from "../../../../hooks";
import { TAppUser, TClaimState } from "../../../contexts/types";

import { ICampaign, ECampaign, ClaimTaskProps } from "./models";

// images
const campaignIcon = require("./img/campaign.png") as string;
const campaignVisitOkxInX=
  require("./img/campaign-vsit-okx-in-x-icon.png") as string;
const campaignVisitNewChannelIcon =
  require("./img/campaign-visit-new-channel-Icon.png") as string;

export const useCampaignApi = (
  user: TAppUser,
  setClaimState?: React.Dispatch<React.SetStateAction<TClaimState | undefined>>,
) => {
  const webApp = useWebApp();
  const [impactOccurred] = useHapticFeedback();

  const [openCheckUidModal, setOpenCheckUidModal] = React.useState(false);
  const [updateUserExternalApi, updateUserState] = useUpdateUserExternalApi();

  const [activeTask, setActiveTask] = React.useState<ICampaign | null>(null);

  const tasksList: ICampaign[] = React.useMemo(
    () => [
      {
        icon: campaignIcon,
        link: "",
        type: ECampaign.OKX_CAMPAIGN,
        title: "Complete OKX campaign",
        reward: 100_000,
        connection: true,
        is_done: false,
      },
      {
        icon: campaignVisitNewChannelIcon,
        link: "https://t.me/okx_ru",
        type: ECampaign.JOIN_NEW_TG_CHANNEL,
        title: "Join OKX News Channel",
        reward: 20_000,
        internalLink: true,
        is_done: false,
      },
      {
        icon: campaignVisitOkxInX,
        link: "https://www.instagram.com/okx_official?igsh=ZTg4ZHM5dGVobzR4",
        type: ECampaign.OKX_IN_X,
        title: "Follow OKX on Instagram",
        reward: 20_000,
        externalLink: true,
        is_done: false,
      },
    ],
    [user?.claim_state?.tasks?.new_okx]
  );

  const formattedNewTasks = React.useMemo(() => {
    return tasksList?.map((item: ICampaign) => ({
      ...item,
      // @ts-ignore
      is_done:
        (user?.claim_state?.tasks &&
          // @ts-ignore
          user.claim_state.tasks?.new_okx?.[item.type]) ||
        false,
    }));
  }, [tasksList, user?.claim_state?.tasks?.new_okx]);

  const tasksToDisplay = React.useMemo(() => formattedNewTasks ,[formattedNewTasks]);

  const completedTasks =
    tasksToDisplay.filter((task) => task.is_done)?.length || 0;
  const allTasks = tasksToDisplay?.length || 0;
  const isAllTasksDone = completedTasks === allTasks;

  const handleClaimTask = ({ task }: ClaimTaskProps, uid?: string) => {
    const payPassMultiplier = user?.claim_state?.payPassMultiplier;

    if (task && payPassMultiplier) {
        const newData = {
          balance: (user.claim_state?.balance || 0) + (task!.reward * payPassMultiplier),
          balance_from_clicks:
            (user.claim_state?.balance_from_clicks || 0) + task!.reward,
          // @ts-ignore
          tasks: user?.claim_state.tasks
            ? {
                ...user.claim_state.tasks,
                new_okx: {
                  // @ts-ignore
                  ...user.claim_state.tasks.new_okx,
                  [task.type]: true,
                  // @ts-ignore
                  uid: user?.claim_state?.tasks?.new_okx?.uid || uid,
                },
              }
            : { [task.type]: true },
        } as TClaimState;
          updateUserExternalApi({ ...newData })
            .then(() => {

              setClaimState?.((prevState) => ({
                ...prevState,
                ...newData,
              }));
            })
            .catch((e: any) => {
              console.log(2);
              console.warn(e);
            });
      }
  };

  const handleModalOpen = (task?: ICampaign) => {
    if (!task) {
      return;
    }

    impactOccurred('heavy');
    setActiveTask(task);
  };

  const handleClose = () => {
    setActiveTask(null);
  };

  const handleOpenCommunityLink = () => {
    webApp.openTelegramLink(TELEGRAM_LINK);
  };

  // CheckUidModal
  const handleCheckUidModalOpen = (task?: ICampaign) => {
    if (!task) {
      return;
    }
    
    impactOccurred('heavy');
    setOpenCheckUidModal(true);
    setActiveTask(task);

  }

  return {
    activeTask,
    onOpenCommunityLink: handleOpenCommunityLink,
    onClose: handleClose,
    isLoading: updateUserState?.isLoading || false,
    isAllTasksDone,
    tasksToDisplay,
    onModalOpen: handleModalOpen,
    completedTasks,
    allTasks,
    setActiveTask,
    onClaimTask: handleClaimTask,
    handleClaimTask,
    onCheckUidModalOpen: handleCheckUidModalOpen,
    openCheckUidModal,
    // @ts-ignore
    isCampaignTaskCompleted: !!user?.claim_state?.tasks?.new_okx?.['OKX_CAMPAIGN'],
    payPassMultiplier: user?.claim_state?.payPassMultiplier
  };
};