import React from "react";

import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

interface IProps {
  path: string;
  icon: string;
  label: string;
  onClick?: () => void;
  activeTaskCount?: number;
  width?: number;
  height?: number;
}

export const Links: React.FC<IProps> = ({
  path,
  icon,
  label,
  onClick,
  activeTaskCount,
  width,
  height,
}) => {
  const [impactOccurred] = useHapticFeedback();

  return (
    <a
      onClick={() => {
        onClick?.();
        impactOccurred('heavy')
      }}
      href={!!onClick ? undefined : path}
      style={{ position: "relative" }}
    >
      {!!activeTaskCount && (
        <div className="active-task">{activeTaskCount}</div>
      )}

      <img width={width} height={height} src={icon} />
      <span className="green-color">{label}</span>
    </a>
  );
};
