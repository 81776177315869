import React from "react";

import { ProgressBar } from "react-loader-spinner";

const tonWcoinIcon = require("./img/wlogo.png") as string;
const tgIcon = require("../img/tgIcon.png") as string;
const x_social = require("../img/x.png") as string;

interface IProps {
  onOpenTelegram: () => void;
  onOpenX: () => void;
  bg?: string;
}

export const MaintenanceLoader: React.FC<IProps> = ({
  onOpenTelegram,
  onOpenX,
  bg,
}) => {
  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          gap: "40px",
          alignItems: "center",
          color: "#fff",
          marginTop: "30px",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "40px",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "57px",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                alignItems: "center",
              }}
            >
              <img src={tonWcoinIcon} width="130px" alt="" />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <p
              className="ab"
              style={{
                whiteSpace: "nowrap",
                fontSize: '28px',
              }}
            >
              Dear Mates
            </p>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '4px',
                }}
            >
              <p
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                  whiteSpace: "nowrap",
                }}
              >
                Technical update in progress…
              </p>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                Expect possible lags or
              </p>
              <p
                style={{
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                freezes.
              </p>
            </div>
          </div>
          <div>
            <div
              style={{
                borderRadius: "20px",
                border: "2px solid #A392FA",
                padding: "6px 12px",
                justifyContent: "space-around",
                display: "flex",
                gap: "8px",
              }}
            >
              <img
                style={{ color: "#A392FA" }}
                width={18}
                height={15}
                src={tgIcon}
                alt=""
                onClick={onOpenTelegram}
              />
              <div
                style={{
                  height: "15px",
                  color: "#fff",
                  width: "1px",
                  backgroundColor: "#A392FA",
                }}
              ></div>
              <img
                style={{ color: "#A392FA" }}
                width={15}
                height={15}
                src={x_social}
                alt=""
                onClick={onOpenX}
              />
            </div>
            <ProgressBar
              visible={true}
              height="80"
              width="80"
              barColor="rgb(151, 71, 255)"
              borderColor="#A392FA"
              ariaLabel="progress-bar-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        </div>
      </div>
      <img
        src={bg}
        alt=""
        style={{
          width: "-webkit-fill-available",
          height: "101vh",
          backgroundPosition: "center",
        }}
      />
    </div>
  );
};
