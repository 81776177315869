import React from "react";

import { CSSTransition } from "react-transition-group";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";

import { IAzurGames } from "../models";
import { useTimerApi } from "../../../../../utils";

import "./index.scss";

interface IProps {
  currentTask: IAzurGames | null;
  onClose: () => void;
  handleUpdateTask: (code: string) => void;
  errorMessage: string | null;
  setErrorMessage: (value: React.SetStateAction<string | null>) => void;
  inputCode?: string;
  setInputCode: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const Modal: React.FC<IProps> = ({
  currentTask,
  onClose,
  handleUpdateTask,
  errorMessage,
  setErrorMessage,
  inputCode,
  setInputCode,
}) => {
  const webApp = useWebApp();

  const isBtnDisabled = !inputCode;

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as string;

    if (Boolean(value)) {
      setInputCode(value as string);
    } else {
      setInputCode(undefined);
    }

    if (errorMessage) {
      setErrorMessage(null);
    }
  };

  const submitCode = () => {
    if (inputCode) {
      handleUpdateTask(inputCode);
    }
  };

  const {
    remainingTime,
    canRedeem,
    isRemainingTimeEnded,
    handleResetRemainingTime,
  } = useTimerApi(currentTask?.timestamp || null);

  const handleReset = () => {
    onClose();
    setInputCode("");
    setErrorMessage(null);
    handleResetRemainingTime();
  };

  const getButtonAction = () => {
    if (!canRedeem && isRemainingTimeEnded) {
      return <div className="azure-games-redeem-button-empty"></div>;
    } else if (canRedeem && isRemainingTimeEnded) {
      return (
        <button
          onClick={isBtnDisabled ? undefined : submitCode}
          className={`azure-games-redeem-button ${
            isBtnDisabled && "btn-disabled"
          }`}
        >
          <p className="ab azure-games-redeem-text">Redeem</p>
        </button>
      );
    } else {
      return (
        <div
          className={`azure-games-redeem-button azure-games-redeem-button-time ${
            isBtnDisabled && "btn-disabled"
          }`}
        >
          <p className="ab azure-games-redeem-text azure-games-redeem-text-time">
            {remainingTime}
          </p>
        </div>
      );
    }
  };

  return (
    <>
      {!!currentTask && (
        <div
          className={`overlay app-modal-root ${!!currentTask ? "blur" : ""}`}
        ></div>
      )}
      <CSSTransition
        in={!!currentTask}
        timeout={2500}
        classNames="fade"
        unmountOnExit
      >
        <div className="overlay app-modal-root">
          <div
            className="app-modal-content-wrapper task-modal-wrapper azure-games-content-wrapper"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="close-modal ab" onClick={handleReset}>
              x
            </div>
            <div className="app-modal-content task-modal-content">
              <img width="60%" src={currentTask?.modalIcon} alt="" />
            </div>
            <div className="azure-games-title-container">
              <p className="ab azure-games-title">{currentTask?.modalTitle}</p>
              <p className="azure-games-subtitle">
                Find a secret code on 3rd level every day!
              </p>
            </div>
            <div className="azure-games-actions">
              <div>
                <div className="azure-games-input-root">
                  <div className="azure-games-input-wrapper">
                    <input
                      value={inputCode}
                      type="text"
                      className={`azure-games-mobile-input  ${
                        !canRedeem ? "btn-disabled" : ""
                      }`}
                      placeholder="Input code here"
                      onChange={(e) => onInputChange(e)}
                    />
                  </div>
                  {getButtonAction()}
                </div>
                <p
                  className={`azure-games-validation-label ${
                    errorMessage && "azure-games-validation-label-visible"
                  }`}
                >
                  {errorMessage}
                </p>
              </div>

              <button className="azure-games-download-button">
                <p className="ab azure-games-download-text">Download</p>
              </button>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  );
};
