import React from "react";

import {
  BackButton,
} from "@vkruglikov/react-telegram-web-app";
import { Wizard } from "react-use-wizard";

import { ROUTES } from "../routers";
import { TStaking } from "../contexts/types";
import { TemplatePages } from "../template-pages";

import {
  StakingStep1,
  StakingStep2,
  StakingStep3,
  StakingStep4,
} from "./steps";
import "./index.scss";
import { usePageStaking } from "./hooks";

// images
const bg = require("./bg.jpg") as string;

export const PERCENT_TO_DAYS: Record<number, number> = {
  3: 2,
  7: 7,
  14: 20,
};

export const PageStaking: React.FC = () => {
  const {onChangeStacking, onUpdateStaking, isFetching, isLoading, staking,  balance} = usePageStaking();

  return (
    <TemplatePages
      isLoading={isLoading}
      isFetching={isFetching}
      bg={bg}
    >
      <BackButton onClick={() => (window.location.hash = ROUTES.MAIN.PATH)} />
      <div className={`page page-1 page-tasks main-wrapper`}>
        <div className="content staking-content">
          <h2 className="main-heading staking-header ab">Staking</h2>
          <Wizard>
            <StakingStep1 />
            <StakingStep2
              onChangeStacking={onChangeStacking}
              selectedValue={staking?.days}
            />
            <StakingStep3
                balance={balance || 0}
                onChangeStacking={onChangeStacking}
            />
            {staking.amount && staking.days && staking.percent && (
              <StakingStep4
                values={staking as TStaking}
                onUpdateStaking={onUpdateStaking}
              />
            )}
          </Wizard>
        </div>
      </div>
    </TemplatePages>
  );
};
