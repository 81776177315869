import React from "react";

import "./index.scss";

const countdownBg = require("./img/countdown-bg.png") as string;


interface IProps {
    onOpen: () => void;
}

export const FinishStackingCountdown: React.FC<IProps> = ({onOpen}) => {
    const targetDate = new Date(Date.UTC(2024, 10, 20)); 

    const calculateDaysLeft = () => {
      const now = new Date();
      const timeDifference = targetDate.getTime() - now.getTime();
      return Math.max(0, Math.ceil(timeDifference / (1000 * 60 * 60 * 24)));
    };
    
    const [daysLeft, setDaysLeft] = React.useState(calculateDaysLeft);
    
    React.useEffect(() => {
      const updateDaysLeft = () => setDaysLeft(calculateDaysLeft);
    
      const timeUntilNextDay =
        24 * 60 * 60 * 1000 -
        (new Date().getUTCHours() * 60 * 60 * 1000 +
          new Date().getUTCMinutes() * 60 * 1000 +
          new Date().getUTCSeconds() * 1000);
      
      const timer = setTimeout(updateDaysLeft, timeUntilNextDay);
    
      return () => clearTimeout(timer);
    }, [daysLeft]);

  return (
    <>
      <div
        className="countdown wai-button"
        style={{ backgroundImage: `url(${countdownBg})` }}
        onClick={onOpen}
      >
        <p className="ab">
          <p>staking closes in</p>{" "}
          <p className="countdown-days"> {daysLeft}</p>{" "}
          <p>{daysLeft === 1 ? "day" : "days"}</p>
        </p>
      </div>
    </>
  );
};
