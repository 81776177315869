import React, { useEffect } from "react";

import { toast } from "react-toastify";

interface IProps {
  show: boolean;
}

export const AbsoluteSpinner: React.FC<IProps> = ({ show }) => {
  const toastId = React.useRef<string | null>(null);

  useEffect(() => {
    if (show) {
      // @ts-ignore
      toastId.current = toast.loading("Your request is pending...", {
        position: "top-center",
        theme: "dark",
      });
    } else if (toastId.current) {
      toast.dismiss(toastId.current);
      toastId.current = null;
    }
  }, [show]);

  return show ? (
    <div className="app-spinner" style={{ top: 0, left: 0 }}></div>
  ) : null;
};
