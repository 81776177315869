import React from "react";

import { motion } from "framer-motion";

import { ETab } from "../type";
import { useShowContentApi } from "../../../hooks";

interface IProps {
  activeTab: ETab;
  onChange: (tab: ETab) => void;
}

export const ToggleGroup: React.FC<IProps> = ({ activeTab, onChange }) => {
  const { canShowContent } = useShowContentApi();

  return (
    <div className="boosters-toggle-wrapper">
      {canShowContent ? (
        <h2
          className={`green-color boosters-header-item task-toggle-label ${
            activeTab === ETab.AZURE ? "selected" : ""
          }`}
          onClick={() => onChange(ETab.AZURE)}
        >
          <b>Azur Games</b>
          {activeTab === ETab.AZURE && (
            <motion.div className="underline" layoutId="underline" />
          )}
        </h2>
      ) : null}
      <h2
        className={`green-color boosters-header-item task-toggle-label ${
          activeTab === ETab.OKX ? "selected" : ""
        }`}
        onClick={() => onChange(ETab.OKX)}
      >
        <b>OKX</b>
        {activeTab === ETab.OKX && (
          <motion.div className="underline" layoutId="underline" />
        )}
      </h2>
      <h2
        className={`green-color boosters-header-item task-toggle-label ${
          activeTab === ETab.ACTIVE ? "selected" : ""
        }`}
        onClick={() => onChange(ETab.ACTIVE)}
      >
        <b>Active</b>
        {activeTab === ETab.ACTIVE && (
          <motion.div className="underline" layoutId="underline" />
        )}
      </h2>
      <h2
        className={`green-color boosters-header-item task-toggle-label ${
          activeTab === ETab.COMPLETED ? "selected" : ""
        }`}
        onClick={() => onChange(ETab.COMPLETED)}
      >
        <b>Completed</b>
        {activeTab === ETab.COMPLETED && (
          <motion.div className="underline" layoutId="underline" />
        )}
      </h2>
    </div>
  );
};
