import React from "react";

import { Counter } from "../../counter";
import { RightAnimation } from "../../page-animation";
import { Link } from "react-router-dom";
import { TAppUser } from "../../contexts/types";
const tasksIcons = require("../img/tasks.png") as string;

interface IProps {
  user: TAppUser;
  isCampaign: boolean;
  isAZureCampaign: boolean;
}

export const TaskCounterAndDescription: React.FC<IProps> = ({
  user,
  isCampaign,
  isAZureCampaign,
}) => {
  return (
    <div className="mates-description">
      <h2
        className={`main-heading ab ${
          isCampaign ? "custom-font-balance" : undefined
        }`}
      >
        Tasks <img width="46px" src={tasksIcons} alt="" />
      </h2>
      <Counter
        isCampaign={isCampaign}
        counter={{
          start: user.claim_state?.balance
            ? Number(user.claim_state?.balance)
            : 0,
          end: user.claim_state?.balance
            ? Number(user.claim_state?.balance)
            : 0,
        }}
      />
      <p
        className={`${
          isCampaign ? "custom-font-balance" : undefined
        } plain-text font-size-16 common-color`}
        style={{
          fontSize: "14px",
          textAlign: "center",
        }}
      >
        {isAZureCampaign
          ? "Complete daily tasks from W-Coin x Azur campaign!"
          : "Earn W-coin rewards by completing simple tasks"}
      </p>
      <RightAnimation>
        <Link
          target="_blank"
          className={`font-size-16 mates-tg-link purple-color ${
            isCampaign ? "collab-color" : ""
          } `}
          to="https://telegra.ph/How-tasks-work-04-06"
        >
          How tasks work?
        </Link>
      </RightAnimation>
    </div>
  );
};
