import React from "react";

import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";

import { useNavigate } from "react-router-dom";

import { UserContext } from "../contexts/user-context";
import { AnimationContext } from "../contexts/animation-context";
import { ROUTES } from "../routers";
import { TClaimState, TStaking } from "../contexts/types";
import { useCreateStakeMutation } from "../feature/users";

const money = require("./money.png") as string;

export const usePageStaking = () => {
  const [impactOccurred] = useHapticFeedback();

  const { handleActiveAnimation } = React.useContext(AnimationContext);

  const navigate = useNavigate();

  const goToDashboard = () => {
    impactOccurred("heavy");
    navigate(ROUTES.DASHBOARD.PATH, { state: { stayHerePage: true } });
  };

  const { user, isLoading, isSuccess, setClaimState } =
    React.useContext(UserContext);

  React.useEffect(() => {
    if (
      user?.claim_state?.stakes?.filter((i) => !i.completed).length === 3 &&
      isSuccess
    ) {
      navigate(ROUTES.DASHBOARD.PATH, { state: { stayHerePage: true } });
    }
  }, [isSuccess, navigate, user?.claim_state?.stakes]);

  const [staking, setStaking] = React.useState<Partial<TStaking>>({});

  const [stackToGet, setStackToGet] = React.useState<TStaking | null>(null);

  const balance = user?.claim_state?.balance;

  const handleChangeStacking = (value: Partial<TStaking>) => {
    setStaking((prevState) => ({ ...prevState, ...value }));
  };

  const [createStake, createStateState] = useCreateStakeMutation();

  const handleUpdateStaking = () => {
    if (staking.days && staking.stakePercentFromBalance) {
      createStake({
        days: staking.days,
        stake_percent_from_balance: staking.stakePercentFromBalance,
      })
        .then((res) => {
          // @ts-ignore
          if (typeof res?.data?.data?.balance === 'number' && res?.data?.data?.stakes) {
            const newData = {
              // @ts-ignore
              balance: res.data.data.balance,
              // @ts-ignore
              stakes: res.data.data.stakes,
            } as TClaimState;

            setClaimState?.((prevState) => ({ ...prevState, ...newData }));
            handleActiveAnimation?.({
              duration: 2,
              blockCount: 15,
              blockImage: money,
            });
            goToDashboard();

          }
        })
        .catch((e: any) => {
          console.warn(e);
        });
    }
  };

  const handleChangeStackToGet = (value: TStaking | null) => {
    setStackToGet(value);
  };

  return {
    onChangeStacking: handleChangeStacking,
    isLoading,
    isFetching: createStateState.isLoading,
    onUpdateStaking: handleUpdateStaking,
    staking,
    balance,
    stackToGet,
    handleChangeStackToGet,
  };
};
