import React from "react";

import { useHapticFeedback, useWebApp } from "@vkruglikov/react-telegram-web-app";

import { useUpdateUserExternalApi } from "../../../../hooks";
import { TAppUser, TClaimState } from "../../../contexts/types";
import {
  formatValues,
  getClientTimestampMilliseconds,
  showToast,
} from "../../../../utils";
import { useUpdateRedeemCodeMutation } from "../../../feature/users";

import { IAzurGames, EAzurGames } from "./models";

// icons
const swordPlayIcon = require("./img/sword-play-icon.png") as string;
const swordPlayModalIcon = require("./img/sword-play-modal-img.png") as string;
const hitmasterIcon = require("./img/hitmaster-icon.png") as string;
const hitmasterModalIcon = require("./img/hitmaster-modal-img.png") as string;

const AZURE_GAMES = [EAzurGames.SWORD_PLAY, EAzurGames.SWORD_PLAY];

export const useCampaignApi = (
  user: TAppUser,
  setClaimState?: React.Dispatch<React.SetStateAction<TClaimState | undefined>>
) => {
  const webApp = useWebApp();
  const [impactOccurred] = useHapticFeedback();

  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [inputCode, setInputCode] = React.useState<string | undefined>(
    undefined
  );
  const [activeTask, setActiveTask] = React.useState<IAzurGames | null>(null);

  const [updateRedeemCode, updateState] = useUpdateRedeemCodeMutation();
  const [updateUserExternalApi, updateUserState] = useUpdateUserExternalApi();

  const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000;
  const [completedTasksCount, setCompletedTasksCount] =
    React.useState<number>(0);

  const tasksList: IAzurGames[] = React.useMemo(
    () => [
      {
        icon: swordPlayIcon,
        modalIcon: swordPlayModalIcon,
        gameLink: "",
        type: EAzurGames.SWORD_PLAY,
        title: "Download Sword Play",
        modalTitle: "Sword Play",
        reward: 100_000,
        timestamp: undefined,
      },
      {
        icon: hitmasterIcon,
        modalIcon: hitmasterModalIcon,
        gameLink: "",
        type: EAzurGames.HITMASTER,
        title: "Play Hitmaster",
        modalTitle: "Hitmaster",
        reward: 100_000,
        timestamp: undefined,
      },
    ],
    []
  );

  const formattedNewTasks = React.useMemo(() => {
    return tasksList?.map((item: IAzurGames) => ({
      ...item,
      // @ts-ignore
      timestamp:
        (user?.claim_state?.tasks &&
          // @ts-ignore
          user.claim_state.tasks?.azureGames?.[item.type]) ||
        undefined,
    }));
  }, [tasksList, user?.claim_state?.tasks?.azureGames]);

  const tasksToDisplay = React.useMemo(
    () => formattedNewTasks,
    [formattedNewTasks, user?.claim_state?.tasks?.azureGames]
  );

  React.useEffect(() => {
    const initialCount = tasksToDisplay.filter((task) => task.timestamp).length;
    setCompletedTasksCount(initialCount);

    const timers = tasksToDisplay.map((task) => {
      if (!task.timestamp) return null;

      const intervalId = setInterval(() => {
        const now = Date.now();
        const elapsedTime = now - task.timestamp;

        if (elapsedTime >= twentyFourHoursInMilliseconds) {
          setCompletedTasksCount((prevCount) => Math.max(prevCount - 1, 0));
          clearInterval(intervalId);
        }
      }, 1000);

      return intervalId;
    });

    return () => {
      timers.forEach((timer) => timer && clearInterval(timer));
    };
  }, [tasksToDisplay, user?.claim_state?.tasks?.azureGames]);

  const allTasks = tasksToDisplay?.length || 0;

  const handleClaimTask = () => {
    if (activeTask) {
      const newData = {
        balance: (user.claim_state?.balance || 0) + activeTask!.reward,
        balance_from_clicks:
          (user.claim_state?.balance_from_clicks || 0) + activeTask!.reward,
        // @ts-ignore
        tasks: user?.claim_state.tasks
          ? {
              ...user.claim_state.tasks,
              azureGames: {
                // @ts-ignore
                ...user.claim_state.tasks.azureGames,
                [activeTask.type]: getClientTimestampMilliseconds(),
              },
            }
          : { [activeTask.type]: true },
      } as TClaimState;

        updateUserExternalApi({ ...newData })
          .then(() => {
            setClaimState?.((prevState) => ({
              ...prevState,
              ...newData,
            }));

            if (activeTask!.reward) {
              showToast(`Received +${formatValues(activeTask!.reward)}`);
            }

            // @ts-ignore
            setActiveTask((prevState) => {
              return {
                ...prevState,
                // @ts-ignore
                timestamp: newData.tasks.azureGames[activeTask.type],
              };
            });
          })
          .catch((e: any) => {
            console.warn(e);
          });
    }
  };

  const handleModalClose = () => {
    setActiveTask(null);
  };

  const handleTaskClick = React.useCallback(
    (task: IAzurGames) => {
      impactOccurred('heavy');
      if (AZURE_GAMES.includes(task.type)) {
        setActiveTask(task);
      } else {
        if (!task) {
          return;
        }

        setActiveTask(task);
      }
    },
    [setActiveTask]
  );

  const handleUpdateTask = (code: string) => {
    updateRedeemCode({
      code,
    })
      .unwrap()
      .then((res) => {
        handleClaimTask?.();
        setInputCode(undefined);
      })
      .catch((e) => {
        if (e.status === 404 || e.status === 400) {
          console.log(e.data.error.message);
          setErrorMessage(e.data.error.message);
        }
        console.error(e);
      });
  };

  return {
    activeTask,
    onModalClose: handleModalClose,
    isLoading: updateUserState?.isLoading || false,
    tasksToDisplay,
    onTaskClick: handleTaskClick,
    allTasks,
    handleUpdateTask,
    errorMessage,
    setErrorMessage,
    updateState,
    completedTasksCount,
    inputCode,
    setInputCode
  };
};
