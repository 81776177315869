import { TaskVisitEveryDay } from "../contexts/types";

export function getCurrentTimestamp() {
  const currentDate = new Date();
  const currentTimestamp = Math.floor(currentDate.getTime() / 1000);
  return currentTimestamp;
}

export function getDifferentDays(
  task_visit_every_day: TaskVisitEveryDay | null | undefined
) {
  const currentTimestamp = getCurrentTimestamp();

  const initialData = {
    visited: task_visit_every_day?.last_visited || currentTimestamp,
    currentLevel: task_visit_every_day?.currentLevel
      ? Number(task_visit_every_day.currentLevel)
      : 0,
    checkedLevels: [...(task_visit_every_day?.checkedLevels || [])],
  };

  const lastTimestamp = initialData.visited - 1;

  const daysDifference = (currentTimestamp - lastTimestamp) / (60 * 60 * 24);

  return daysDifference;
}

export function checkNickname(nickname?: string, timestamp?: number): boolean {
  if (nickname) {
    const pattern = /(w\s*[-−–—‐]\s*(c|с)oin|wcoin)/i;
    nickname = nickname.toLowerCase();

    const isOk = pattern.test(nickname);

    if (isOk && !timestamp) {
      return true;
    } else if (isOk && timestamp) {
      const currentTimestamp = getCurrentTimestamp();
      const oneDayInSeconds = 24 * 60 * 60;
      if (currentTimestamp - timestamp >= oneDayInSeconds) {
        return true;
      }
    }
  }

  return false;
}


export function shouldShow(percent: number): boolean {
  if (percent < 1 || percent > 100) {
      return false;
  }

  const randomValue = Math.random() * 100;

  return randomValue < percent;
}


export function hasDayPassedSince(lastDateUtc?: number): boolean {
  const nowTimestamp = Math.floor(Date.now() / 1000);

  if (!lastDateUtc) {
    return true;
  }

  const secondsInADay = 24 * 60 * 60;
  return nowTimestamp - lastDateUtc >= secondsInADay;
}

export function updateDateIfNeeded(lastDateUtc?: number): number {
  const nowTimestamp = Math.floor(Date.now() / 1000);

  if (!lastDateUtc || hasDayPassed(lastDateUtc)) {
    return nowTimestamp;
  }

  return lastDateUtc;
}

export function hasDayPassed(lastTimestamp: number): boolean {
  const nowTimestamp = Math.floor(Date.now() / 1000);
  const secondsInADay = 24 * 60 * 60;

  return nowTimestamp - lastTimestamp >= secondsInADay;
}