import React from "react";

import { usePageClaimApi } from "./hooks";

import "./index.scss";
import { Template } from "../template";
import { ClaimButton } from "./claim-button";
import { ClaimModal } from "./claim-modal";
import { Counter } from "../counter";
import { getClaimIcon } from "../../utils";
import { BottomAnimation } from "../page-animation";
import { Header } from "./header";
import { PagesLinks } from "./pages-links";
import { Footer } from "./footer";
import { CountdownModal } from "./countdown-modal";

// TODO: ADD AFTER RUNE
// const bg = require("../template/main-bg-choose.png");
// const runeBg = require("./img/rune-loader.png");
// const walletBg = require("../template/walletBg.png") as string;
const loaderBg = require("./img/okx-bg.jpg") as string;

export const PageClaim: React.FC = () => {
  const {
    isLoading,
    isFetching,
    isSuccess,
    energy,
    maxEnergy,
    onClickChange,
    counter,
    disableBtn,
    addToBalance,
    onGoToDashboard,
    workerModalStatus,
    onWorkerClaimBalance,
    onCloseModal,
    earnByMinute,
    claimState,
    activeTaskCount,
    setHasLoadedOnce,
    hasLoadedOnce,
    payPassMultiplier,
    showAnimation,
  } = usePageClaimApi();

  const canShowWorkerModal = !!(
    workerModalStatus?.readyToShowWorkerModal &&
    !workerModalStatus?.alreadyOpenedModal &&
    earnByMinute
  );
  const [showCountdownModal, setShowCountdownModal] = React.useState(false);

  const handleCountdownModalChange = (value: boolean) => {
    setShowCountdownModal(value);
  };

  return (
    <Template
      isLoading={isLoading}
      isMainPage={true}
      isFetching={isFetching}
      setHasLoadedOnce={setHasLoadedOnce}
      hasLoadedOnce={hasLoadedOnce}
      bg={loaderBg}
    >
      {(canShowWorkerModal || showCountdownModal) && (
        <div
          className={`overlay app-modal-root ${
            (canShowWorkerModal || showCountdownModal) ? "blur" : ""
          }`}
        ></div>
      )}
      <ClaimModal
        canShowWorkerModal={canShowWorkerModal}
        value={earnByMinute}
        onClose={onCloseModal}
        onClaim={onWorkerClaimBalance}
        disabled={isLoading}
      />
      <CountdownModal
        show={showCountdownModal}
        onClose={() => handleCountdownModalChange(false)}
      />
      {isSuccess && (
        <div className="page-claim">
          <Header onCountdownModalChange={handleCountdownModalChange} />
          <div className="page-claim-content-wrapper">
            <div className="content main-wrapper page-claim-main-wrapper">
              <Counter counter={counter} additionalComponent={<PagesLinks />} />
              <div className="box-claim-wrapper">
                <ClaimButton
                  showAnimation={showAnimation}
                  onClick={onClickChange}
                  energy={energy}
                  maxEnergy={maxEnergy}
                  clickMultiplier={addToBalance}
                  disableBtn={disableBtn}
                  claimIcon={getClaimIcon(claimState?.skins)}
                  isSpinnerSkin={claimState?.skins?.shuriken?.selected}
                  payPassMultiplier={payPassMultiplier}
                />
                <BottomAnimation>
                  <Footer
                    onGoToDashboard={onGoToDashboard}
                    activeTaskCount={activeTaskCount}
                  />
                </BottomAnimation>
              </div>
            </div>
          </div>
        </div>
      )}
    </Template>
  );
};
