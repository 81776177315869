import React from "react";

import { CarouselTemplatePage } from "../carousel-template-page";

import "./index.scss";
import { GOLD_COLOR } from "../../../../../../consts";

const img1 = require("./img/slide-4-img.png") as string;

export const Slide4: React.FC = () => {
  return (
    <CarouselTemplatePage title="Discover" subtitle="Leagues">
      <div className="slides-wrapper">
        <div>
        <img width="130px" height="161.79px" src={img1} alt="" />
        </div>
        <div className="slides-text-wrapper">
        <div className="slides3-desc-wrapper-root-wrapper">
          <p className="slides-desc slides-desc-title">What is a League?</p>
          <p className="slides-desc">
            A league in W-AI Bot is a ranking system that tracks your progress.
            Leagues range from{" "}
            <p style={{ color: "#2D83EC", display: "inline-block" }}>Newbie</p>{" "}
            to{" "}
            <p style={{ color: GOLD_COLOR, display: "inline-block" }}>Legend</p>,
            each providing access to new feature levels and greater rewards.
            Progressing through leagues enhances your bot’s efficiency and
            maximizes your passive income potential.
          </p>
        </div>
        <div className="slides3-desc-wrapper-root-wrapper">
          <p className="slides-desc slides-desc-title">
            How to Advance in Leagues?
          </p>
          <p className="slides-desc">
            To advance to the next league, you need to upgrade at least one
            feature to the required level: level 5, 10, or 15, depending on the
            league.
          </p>
        </div>
        </div>
      </div>
    </CarouselTemplatePage>
  );
};
