import React from "react";

import { motion } from "framer-motion";

interface IProps extends React.PropsWithChildren {
  fullWidth?: boolean
  styles?: {}
}

export const RightAnimation: React.FC<IProps> = ({ children, fullWidth, styles }) => {
  return (
    <motion.div
      initial={{ opacity: 0, x: "100vw" }}
      animate={{ opacity: 1, x: 0 }}
      transition={{
        type: "spring", 
        stiffness: 1000,
        damping: 30,
        duration: 0.1,
      }}
      style={{ alignItems: "center", display: "flex", flexDirection: "column", width: fullWidth ? '100%' : undefined, ...styles ?? undefined  } }
    >
      {children}
    </motion.div>
  );
};

export const ZoomInAnimation: React.FC<IProps> = ({ children }) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.2 }}
      style={{ alignItems: "center", display: "flex", flexDirection: "column" }}
    >
      {children}
    </motion.div>
  );
};

export const BottomAnimation: React.FC<IProps> = ({ children }) => {
  const variants = {
    hidden: { opacity: 0, y: "100vh" },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        stiffness: 1000,
        damping: 50,
        duration: 10,
      },
    },
  };
  return (
    <motion.div initial="hidden" animate="visible" variants={variants}>
      {children}
    </motion.div>
  );
};
