import { TaskVisitEveryDay } from "../contexts/types";

import { ETask } from "./type";

interface ITaskReward {
  value: number;
  reward: number;
}

interface ITaskVisit {
  level: number;
  reward: number;
}

export const TASK_TRY_STAKING_CLAIMED: Record<string, ITaskReward> = {
  0: {
    value: 10000,
    reward: 5000,
  },
  1: {
    value: 20000,
    reward: 10000,
  },
  2: {
    value: 40000,
    reward: 15000,
  },
  3: {
    value: 80000,
    reward: 20000,
  },
  4: {
    value: 160000,
    reward: 25000,
  },
  5: {
    value: 320000,
    reward: 30000,
  },
  6: {
    value: 640000,
    reward: 35000,
  },
  7: {
    value: 720000,
    reward: 40000,
  },
  8: {
    value: 810000,
    reward: 45000,
  },
  9: {
    value: 900000,
    reward: 50000,
  },
  10: {
    value: 1000000,
    reward: 50000,
  },
  11: {
    value: 1200000,
    reward: 20000,
  },
  12: {
    value: 1400000,
    reward: 20000,
  },
  13: {
    value: 1600000,
    reward: 20000,
  },
  14: {
    value: 1800000,
    reward: 20000,
  },
  15: {
    value: 2000000,
    reward: 20000,
  },
  16: {
    value: 2200000,
    reward: 20000,
  },
  17: {
    value: 2400000,
    reward: 20000,
  },
  18: {
    value: 2600000,
    reward: 20000,
  },
  19: {
    value: 2800000,
    reward: 20000,
  },
  20: {
    value: 3000000,
    reward: 20000,
  },
  21: {
    value: 3200000,
    reward: 20000,
  },
  22: {
    value: 3400000,
    reward: 20000,
  },
  23: {
    value: 3600000,
    reward: 20000,
  },
  24: {
    value: 3800000,
    reward: 20000,
  },
  25: {
    value: 4000000,
    reward: 20000,
  },
  26: {
    value: 4200000,
    reward: 20000,
  },
  27: {
    value: 4400000,
    reward: 20000,
  },
  28: {
    value: 4600000,
    reward: 20000,
  },
  29: {
    value: 4800000,
    reward: 20000,
  },
  30: {
    value: 5000000,
    reward: 20000,
  },
};

export const TASK_CLICK: Record<string, ITaskReward> = {
  0: {
    value: 1000,
    reward: 2000,
  },
  1: {
    value: 2000,
    reward: 4000,
  },
  2: {
    value: 4000,
    reward: 8000,
  },
  3: {
    value: 6000,
    reward: 16000,
  },
  4: {
    value: 8000,
    reward: 32000,
  },
  5: {
    value: 10000,
    reward: 60000,
  },
  6: {
    value: 15000,
    reward: 64000,
  },
  7: {
    value: 20000,
    reward: 70000,
  },
  8: {
    value: 25000,
    reward: 80000,
  },
  9: {
    value: 30000,
    reward: 90000,
  },
  10: {
    value: 60000,
    reward: 100000,
  },
  11: {
    value: 70000,
    reward: 120000,
  },
  12: {
    value: 80000,
    reward: 140000,
  },
  13: {
    value: 90000,
    reward: 160000,
  },
  14: {
    value: 100000,
    reward: 180000,
  },
  15: {
    value: 120000,
    reward: 200000,
  },
  16: {
    value: 140000,
    reward: 220000,
  },
  17: {
    value: 160000,
    reward: 240000,
  },
  18: {
    value: 180000,
    reward: 260000,
  },
  19: {
    value: 200000,
    reward: 280000,
  },
  20: {
    value: 250000,
    reward: 350000,
  },
  21: {
    value: 300000,
    reward: 500000,
  },
  22: {
    value: 400000,
    reward: 600000,
  },
  23: {
    value: 500000,
    reward: 700000,
  },
  24: {
    value: 600000,
    reward: 800000,
  },
  25: {
    value: 700000,
    reward: 900000,
  },
  26: {
    value: 800000,
    reward: 1000000,
  },
  27: {
    value: 900000,
    reward: 1100000,
  },
  28: {
    value: 1000000,
    reward: 1200000,
  },
};

export const TASK_VISIT_EVERY_DAY: Record<number, ITaskVisit> = {
  1: {
    reward: 2000,
    level: 1,
  },
  2: {
    reward: 4000,
    level: 2,
  },
  3: {
    reward: 6000,
    level: 3,
  },
  4: {
    reward: 8000,
    level: 4,
  },
  5: {
    reward: 10000,
    level: 5,
  },
  6: {
    reward: 12000,
    level: 6,
  },
  7: {
    reward: 14000,
    level: 7,
  },
};

export const TASK_BALANCE: Record<string, ITaskReward> = {
  0: {
    value: 100_000,
    reward: 10_000,
  },
  1: {
    value: 200_000,
    reward: 20_000,
  },
  2: {
    value: 400_000,
     reward: 40_000
  },
  3: {
    value: 800_000,
    reward: 80_000,
  }
}

export const MAX_TASK_TRY_STAKING_LEVEL = Object.keys(
  TASK_TRY_STAKING_CLAIMED
).length;
export const MAX_CLICK_LEVEL = Object.keys(TASK_CLICK).length;
export const MAX_BALANCE_LEVEL = Object.keys(TASK_BALANCE).length;

export const MAX_TASK_VISIT_EVERY_DAY =
  Object.keys(TASK_VISIT_EVERY_DAY).length;

export const getNextLevel = (
  type: ETask.TASK_CLICK_LEVEL | ETask.TASK_TRY_STAKING_CLAIMED | ETask.TASK_BALANCE_LEVEL,
  level?: number
) => {
  const empty = {
    value: 0,
    reward: 0,
  };
  if (level === undefined) {
    return empty;
  }

  if (type === ETask.TASK_CLICK_LEVEL) {
    return TASK_CLICK[level] || empty;
  } else if (type === ETask.TASK_BALANCE_LEVEL) {
    return TASK_BALANCE[level] || empty;
  } else {
    return TASK_TRY_STAKING_CLAIMED[level] || empty;
  }
};

export const canGetReward = (
  type: ETask.TASK_CLICK_LEVEL | ETask.TASK_TRY_STAKING_CLAIMED | ETask.TASK_BALANCE_LEVEL,
  level?: number,
  amount?: number
) => {
  if (level === undefined || amount === undefined) {
    return false;
  } else {
    return amount >= getNextLevel(type, level).value;
  }
};

export const getLastVisitDayLevel = (level: number = 0): string => {
    const nextLevel = level + 1;
    return level >= 9 ? nextLevel.toString() : `🔥${nextLevel}`;
};

export const getTaskVisitEveryDayReward = (task_visit_every_day?: TaskVisitEveryDay | null) => {
    if (task_visit_every_day) {
      if (task_visit_every_day.currentLevel >= MAX_TASK_VISIT_EVERY_DAY) {
        return TASK_VISIT_EVERY_DAY?.[MAX_TASK_VISIT_EVERY_DAY]?.reward;
      }

      return TASK_VISIT_EVERY_DAY?.[
        (task_visit_every_day.currentLevel || 0) + 1
      ]?.reward;
    }

    return TASK_VISIT_EVERY_DAY[1].reward;
  }


  export const ADSGRAM_FIELD = 'task_ads_gram1';
  export const ADSGRAM_SHOW_TIMES = 3;
