import React from "react";

import "./index.scss";
import { RightAnimation } from "../../../page-animation";
import { TradeButton } from "./trade-button";
import { TELEGRAM_LINK } from "../../../../consts";
import { useWebApp } from "@vkruglikov/react-telegram-web-app";
import { isPayPasPurchased } from "../../../../utils";

interface IProps {
  balance: number;
  maxBalance: number;
  currentTradeSessionStartAt: number | null;
  onClaim: () => void;
  remainingTime: string | null;
  canClaim: boolean;
  payPassMultiplier?: number;
}

export const ActionButton: React.FC<IProps> = ({
  balance,
  maxBalance,
  currentTradeSessionStartAt,
  onClaim,
  remainingTime,
  canClaim,
  payPassMultiplier
}) => {  
  const webApp = useWebApp();

  const handleClaim = () => {
    webApp.openTelegramLink(TELEGRAM_LINK);
    onClaim();
  }


  const payPasPurchased = isPayPasPurchased(payPassMultiplier);

  const getButton = () => {
    if (canClaim) {
      return (
        <div onClick={handleClaim} className={`${isPayPasPurchased(payPassMultiplier) ? 'wai-action-text-timer-claim-pay-pass' : 'wai-action-text-timer-claim'} ab`}>
          {payPasPurchased ? 'Claim x2' : 'Claim'}  
        </div>
      );
    }
     else if (currentTradeSessionStartAt === null) {
      return (
        <TradeButton onClaim={onClaim} />
      );
    } 
    else {
      return remainingTime ? <div className="wai-action-text-timer-claim ab">
        <div style={{width: '120px', height: '29.9px', display: 'flex', justifyContent: 'center' }}>{remainingTime}</div>
      </div> : null;
    }
  };

  return (
    <div className="wai-action-button-root">
       <RightAnimation>
      <div className="wai-action-wrapper">
        <p className="white-color wai-action-text">
          Per session: {balance.toFixed(2)}/{maxBalance.toFixed(2)} WAI
        </p>
        <div className={`action-btn-wrapper ${payPasPurchased ? 'action-btn-wrapper-pay-pass-width' : ''}`}>
          {getButton()}
        </div>
      </div>
      </RightAnimation>
    </div>
  );
};
